import Layout from "../Components/Layout";
import { Row, Col, Form, Input, Button, Tooltip, Select, Modal, message, Spin } from "antd";
import axios from "../config/axios";
import React, { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";

// import { AiOutlineSearch } from "react-icons/ai";

import { RiCloseCircleLine } from "react-icons/ri";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";

const { Option } = Select;

const Zones = () => {
    const [form] = Form.useForm();
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    // const [searchTerm, setSearchTerm] = useState("");
    const [spin, setSpin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [addAreaLoading, setAddAreaLoading] = useState(false);
    const [updateAreaLoading, setUpdateAreaLoading] = useState(false);
    const [allZones, setAllZones] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const [refresh, setRefresh] = useState(null);

    // handle form with  all data
    const handleAddArea = async (values) => {
        setAddAreaLoading(true);
        await axios.post("/api/fundamentals/zones", values, {
            withCredentials: true,
        });
        message.success("Added New Area");
        form.resetFields();
        setAddAreaLoading(false);
        setRefresh(Math.random());
    };

    // handle form with  all data
    const handleUpdateArea = async (values) => {
        setUpdateAreaLoading(true);
        await axios.put(`/api/fundamentals/zones/${modalData.id}`, values, {
            withCredentials: true,
        });
        message.success("Updated the Area");
        setUpdateAreaLoading(false);
        setShowModal(false);
        setRefresh(Math.random());
    };

    useEffect(() => {
        const getAllCities = async () => {
            const res = await axios.get("/api/fundamentals/get-locations?ref_id=all_districts");
            setAllCities(res.data);
        };
        getAllCities();
    }, []);

    useEffect(() => {
        const getAllAreas = async () => {
            setSpin(true);
            const res = await axios.get("/api/public/fundamentals/zones");
            setAllZones(res.data);
            setSpin(false);
            setLoading(false);
        };
        getAllAreas();
    }, [refresh]);

    return (
        <Layout pageName="Zones">
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    {/* add zones form */}
                    <div className="card-primary">
                        <h2 className="primary_h2">Add New Area</h2>
                        <Form name="addNewSubSubCategory" form={form} onFinish={handleAddArea} layout="vertical">
                            <Form.Item
                                name="city"
                                label="Select City"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select City",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select City"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {allCities.map((option, index) => (
                                        <Option value={option.name} key={index}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item label="Area Name" name="name" rules={[{ required: true, message: "Please Add Area Name" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={addAreaLoading}>
                                    Add New Area
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <div className="card-primary">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                            <h2 className="primary_h2">All Areas</h2>
                            {/* <Input
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                                placeholder="Search Areas"
                                suffix={<AiOutlineSearch size={20} />}
                                bordered={false}
                                style={{ width: 230 }}
                            /> */}
                        </div>
                        {loading ? (
                            <SimpleLoader height="100%" />
                        ) : (
                            <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                                <table>
                                    <thead>
                                        <tr className="tr__head">
                                            <th className="th"> Name </th>
                                            <th className="th"> City </th>
                                            <th className="th"> Actions </th>
                                        </tr>
                                    </thead>
                                    {allZones?.map((zone, index) => (
                                        <tbody key={index}>
                                            <tr className="tr">
                                                <td className="td"> {zone.name} </td>
                                                <td className="td"> {zone.city} </td>
                                                <td className="td" style={{ cursor: "pointer" }}>
                                                    <Tooltip title="Edit zones" placement="left">
                                                        <FiEdit
                                                            className="sh__edit__products-icon"
                                                            onClick={() => {
                                                                setModalData(zone);
                                                                setShowModal(true);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                                <div className="pagination__wrapper">
                                    {/* <Pagination
                                        showTotal={showTotal}
                                        onShowSizeChange={onShowSizeChange}
                                        size="small"
                                        total={subSubCategoryCount}
                                        onChange={handleCurrentPage}
                                        pageSize={limit}
                                        current={currentPage}
                                    /> */}
                                </div>
                            </Spin>
                        )}

                        {/* modal for edit category */}
                        {modalData && (
                            <Modal
                                visible={showModal}
                                onCancel={() => {
                                    setShowModal(false);
                                    setModalData(null);
                                }}
                                afterClose={() => {
                                    setModalData(null);
                                }}
                                closeIcon={<RiCloseCircleLine size={20} />}
                                width={600}
                                footer={null}
                                centered
                            >
                                <h2 className="primary_h2"> Update Zone </h2>
                                <Form
                                    name="updateZones"
                                    layout="vertical"
                                    onFinish={handleUpdateArea}
                                    initialValues={{
                                        ...modalData,
                                    }}
                                >
                                    <Form.Item
                                        name="city"
                                        label="Select City"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select City",
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select City"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {allCities.map((option, index) => (
                                                <Option value={option.name} key={index}>
                                                    {option.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label="Area Name" name="name" rules={[{ required: true, message: "Please Add Area Name" }]}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={updateAreaLoading}>
                                            Update Area
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        )}
                    </div>
                </Col>
            </Row>
        </Layout>
    );
};

export default Zones;
