import { message, Tag } from "antd";

const SkuWithCopy = ({ sku }) => {
    return (
        <Tag
            color="#000"
            style={{ cursor: 'pointer' }}
            title="Copy SKU to Clipboard"
            onClick={() => {
                navigator.clipboard.writeText(sku);
                message.success('SKU Copied to clipboard!')
            }}
        >
            {sku}
        </Tag>
    );
}

export default SkuWithCopy;