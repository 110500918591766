import { useState, useEffect } from 'react';

// importing components
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Tag, Tooltip } from "antd";
import WarehouseLayout from "../../../Components/WarehouseLayout/WarehouseLayout";
import NoData from '../../../Components/Library/NoData';
import axios from '../../../config/axios';
import SimpleLoader from '../../../Components/SimpleLoader/SimpleLoader';

// importing icons
import { FiEdit } from "react-icons/fi";
import { RiCloseCircleLine } from "react-icons/ri";

const { Option } = Select;

const Racks = () => {
    const [form] = Form.useForm();
    const [addRackLoading, setAddRackLoading] = useState(false);
    const [updateRackLoading, setUpdateRackLoading] = useState(false);
    const [allWarehousesForSelect, setAllWarehousesForSelect] = useState([]);
    const [allRacks, setAllRacks] = useState([]);
    const [allRacksLoading, setAllRacksLoading] = useState(true);
    const [spin, setSpin] = useState(false);
    const [modalDataForEdit, setModalDataForEdit] = useState(null);
    const [showEditRackModal, setShowEditRackModal] = useState(false);
    const [warehouseIdForFilter, setWarehouseIdForFilter] = useState(null);
    const [refresh, setRefresh] = useState(null);


    useEffect(() => {
        const getAllWarehouse = async () => {
            const res = await axios.get('/api/warehouses', { withCredentials: true })
            setAllWarehousesForSelect(res.data);
        }
        getAllWarehouse()
    }, []);

    useEffect(() => {
        const getAllRacks = async () => {
            setSpin(true)
            const res = await axios.get('/api/warehouses/racks', {
                withCredentials: true, params: {
                    warehouse: warehouseIdForFilter ?? null
                }
            })
            setAllRacks(res.data)
            setSpin(false)
            setAllRacksLoading(false)
        }
        getAllRacks()
    }, [refresh, warehouseIdForFilter]);



    const handleAddRack = async (values) => {
        setAddRackLoading(true)
        await axios.post('/api/warehouses/racks', values, { withCredentials: true })
        message.success('Added New Rack')
        setAddRackLoading(false)
        form.resetFields()
        setRefresh(Math.random())
    }

    const handleUpdateRack = async (values) => {
        setUpdateRackLoading(true)
        await axios.patch(`/api/warehouses/racks/${modalDataForEdit.id}`, values, { withCredentials: true })
        setUpdateRackLoading(false)
        message.success("Updated Rack Data")
        setShowEditRackModal(false)
        setRefresh(Math.random())
    }

    return (
        <WarehouseLayout>
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <div className="card">
                        <h2 className="primary_h2"> Add New Rack </h2>

                        {/* add new racks form */}
                        <Form name="addNewRack" form={form} onFinish={handleAddRack} layout="vertical">

                            <Form.Item
                                name="warehouse"
                                label="Select Warehouse"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select Corresponding Warehouse",
                                    },
                                ]}
                            >
                                <Select placeholder="Select a Warehouse">
                                    {allWarehousesForSelect?.map((option, index) => (
                                        <Option value={option.id} key={index}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item label="Rack Code" name="code" rules={[{ required: true, message: "Please Add Rack Code" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" loading={addRackLoading} htmlType="submit" block style={{ marginTop: 10 }}>
                                    Add New Rack
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>


                <Col lg={14} sm={24} xs={24}>
                    <div className="card">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                            <h2 className="primary_h2">All Racks</h2>
                            <Select placeholder="Filter By Warehouse" style={{ flexBasis: "35%" }} allowClear onChange={(value) => setWarehouseIdForFilter(value)}>
                                {allWarehousesForSelect?.map((option, index) => (
                                    <Option value={option.id} key={index}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        {allRacksLoading ? (
                            <SimpleLoader height="100%" />
                        ) : (
                            <>
                                {allRacks.length > 0 ? (
                                    <Spin spinning={spin} indicator={<SimpleLoader height={"100%"} />}>
                                        <table>
                                            <thead>
                                                <tr className="tr__head">
                                                    <th className="th"> Warehouse </th>
                                                    <th className="th"> Code </th>
                                                    <th className="th" style={{ textAlign: "center" }}> Actions </th>
                                                </tr>
                                            </thead>
                                            {allRacks.map((rack, index) => (
                                                <tbody key={index}>
                                                    <tr className="tr">
                                                        <td className="td"> {rack.warehouse.name} </td>

                                                        <td className="td">
                                                            <Tag color="#f50"> {rack.code} </Tag>
                                                        </td>

                                                        <td className="td" style={{ cursor: "pointer", textAlign: 'center' }}>
                                                            <Tooltip title="Edit Rack Data" placement="left">
                                                                <FiEdit
                                                                    className="sh__edit__products-icon"
                                                                    onClick={() => {
                                                                        setModalDataForEdit(rack);
                                                                        setShowEditRackModal(true);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </table>
                                    </Spin>
                                ) : (
                                    <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                                        <NoData />
                                    </Spin>
                                )}
                            </>

                        )}

                        {modalDataForEdit && (
                            <Modal
                                visible={showEditRackModal}
                                onCancel={() => {
                                    setShowEditRackModal(false);
                                    setModalDataForEdit(null);
                                }}
                                afterClose={() => {
                                    setModalDataForEdit(null);
                                }}
                                closeIcon={<RiCloseCircleLine size={20} />}
                                width={600}
                                footer={null}
                                centered
                            >
                                <h2 className="primary_h2"> Update Rack Data </h2>
                                <Form
                                    name="updateRack"
                                    onFinish={handleUpdateRack}
                                    layout="vertical"
                                    initialValues={{
                                        ...modalDataForEdit,
                                    }}
                                >
                                    <Form.Item label="Rack Code" name="code" rules={[{ required: true, message: "Please Add Rack Code" }]}>
                                        <Input />
                                    </Form.Item>

                                    {/* <Form.Item
                                        name="warehouse"
                                        label="Select Warehouse"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Select Corresponding Warehouse",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select a Warehouse">
                                            {allWarehousesForSelect?.map((option, index) => (
                                                <Option value={option.id} key={index}>
                                                    {option.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={updateRackLoading}>
                                            Update Rack Data
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        )}
                    </div>
                </Col>
            </Row>
        </WarehouseLayout>
    )
}

export default Racks;