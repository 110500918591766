import { useState, useEffect } from "react";
import moment from "moment";
import { Pagination, Spin, Select, Input, Tag, DatePicker, Avatar, Switch, Button, Form, Row, Col } from "antd";

// importing axios
import axios from "../../config/axios";

// importing components
import Layout from "../../Components/Layout";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";
import NoData from '../../Components/Library/NoData';

// importing styles
import styles from "./OrderItems.module.scss";

//importing icons
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";

// importing utils
import { colorByStatus, formattedPrice } from "../../utils/helpers";

// importing data
import { OrderItemsStatus } from './data';

const OrderItems = () => {

    // states
    const [allOrderItems, setAllOrderItems] = useState([]);
    const [orderItemsCount, setOrderItemsCount] = useState(0);
    const [orderItemsLoading, setOrderItemsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [spin, setSpin] = useState(false);
    const [limit, setLimit] = useState(20);
    const [filters, setFilters] = useState({});
    const [form] = Form.useForm();

    useEffect(() => {

        // get all order items, access admin
        (async () => {
            setSpin(true);
            const res = await axios.get("/api/admin/orders/order-items", {
                withCredentials: true,
                params: {
                    page: currentPage,
                    limit: limit,
                    order_id: filters.order_id ?? null,
                    status: filters.status ?? null,
                    start: filters.range ? moment(filters.range[0]).format('YYYY-MM-DD') : null,
                    end: filters.range ? moment(filters.range[1]).format('YYYY-MM-DD') : null,
                },
            });
            setOrderItemsCount(res.data.count);
            setAllOrderItems(res.data.results);
            setSpin(false);
            setOrderItemsLoading(false);
        })();

    }, [currentPage, limit, filters]);

    return (
        <Layout pageName="Order Items">
            {/* filter Part */}
            <div className={styles.filter}>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={(values) => {
                        setCurrentPage(1);
                        setFilters(values);
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col lg={5} sm={24} xs={24}>
                            <Form.Item
                                name={'order_id'}
                                style={{ margin: 0 }}
                            >
                                <Input placeholder="Search By Order ID" />
                            </Form.Item>
                        </Col>

                        <Col lg={5} sm={24} xs={24}>
                            <Form.Item
                                name={'status'}
                                style={{ margin: 0 }}
                            >
                                <Select placeholder="Search By Status">
                                    {OrderItemsStatus.map((status, index) => (
                                        <Select.Option value={status.value} key={index}>
                                            {status.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={6} sm={24} xs={24}>
                            <Form.Item name={'range'} style={{ margin: 0 }}>
                                <DatePicker.RangePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        <Col lg={8} sm={24} xs={24}>
                            <Form.Item style={{ margin: 0 }}>
                                <div className={styles.actions_btn_wrapper}>
                                    <Button
                                        htmlType='submit'
                                        type='primary'
                                        icon={<SearchOutlined />}
                                    >
                                        Apply Filters
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setCurrentPage(1);
                                            form.resetFields();
                                            setFilters({});
                                        }}
                                        type='danger'
                                        icon={<CloseCircleOutlined />}
                                        disabled={filters.order_id || filters.status || filters.range ? false : true}
                                    >
                                        Clear Filters
                                    </Button>
                                </div>

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            {/* Table Of Order Items */}
            <div className={styles.orderItems}>
                <h2 className="primary_h2">All Order Items</h2>
                {orderItemsLoading ? (
                    <SimpleLoader height="100%" />
                ) : (
                    <>
                        {allOrderItems.length > 0 ? (
                            <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                                <div className={styles.orderItemsTable}>
                                    <table>
                                        <thead>
                                            <tr className="tr__head">
                                                <th className="th"> Order ID </th>
                                                <th className="th"> Merchant Name</th>
                                                <th className="th">Order Date</th>
                                                <th className="th">Product Name</th>
                                                <th className="th">SKU</th>
                                                <th className="th">Price</th>
                                                <th className="th">Quantity</th>
                                                <th className="th">Total</th>
                                                <th className="th">Settled</th>
                                                <th className="th">Image</th>
                                                <th className="th"> Status </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allOrderItems.map((item, index) => (
                                                <tr className="tr" key={index}>
                                                    <td className="td">
                                                        <Tag color="#f50"> {item.order} </Tag>
                                                    </td>
                                                    <td className="td"> {item.merchant_name} </td>
                                                    <td className="td">{moment(item.timestamp).format("MMMM D, YYYY - LT")}</td>
                                                    <td className="td"> {item.name} </td>
                                                    <td className="td"> {item.sku} </td>
                                                    <td className="td"> ৳{formattedPrice(item.order_time_price)} </td>
                                                    <td className="td"> {item.quantity} </td>
                                                    <td className="td">৳{formattedPrice(item.order_time_price * item.quantity)}</td>
                                                    <td className="td">
                                                        <Switch disabled defaultChecked={item.i_s} />
                                                    </td>
                                                    <td className="td">
                                                        <Avatar src={item.image} alt="product" size={55} shape="square" />
                                                    </td>
                                                    <td className="td">
                                                        <Tag color={colorByStatus(item.status)} style={{ textTransform: "capitalize" }}>
                                                            {item.status}
                                                        </Tag>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="sh__pagination__center">
                                    <Pagination
                                        showTotal={(total) => `Total ${total} Order Items`}
                                        total={orderItemsCount}
                                        onChange={(page) => setCurrentPage(page)}
                                        defaultPageSize={limit}
                                        current={currentPage}
                                        pageSizeOptions={[20, 50, 100]}
                                        onShowSizeChange={(current, pageSize) => setLimit(pageSize)}
                                        showLessItems
                                    />
                                </div>
                            </Spin>
                        ) : (
                            <Spin spinning={spin} indicator={<SimpleLoader height={"30vh"} />}>
                                <NoData message="No Order Items Found!" />
                            </Spin>
                        )}
                    </>

                )}
            </div>
        </Layout>
    );
}

export default OrderItems;
