import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

//importing components
import AuthLayout from "../Components/Auth/AuthLayout";
import { Row, Col, Input, message, Button, Form } from "antd";

//importing api services
import { AuthContext } from "../Contexts/AuthContext";
import axios from "../config/axios";

// importing icons
import { Loading3QuartersOutlined } from "@ant-design/icons";

const Login = () => {
    const authContext = useContext(AuthContext);
    const history = useHistory()

    const [step, setStep] = useState(1);
    const [getOTPLoading, setGetOTPLoading] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);

    const [payload, setPayload] = useState({
        phone_number: "",
        password: "",
        hashed_otp: "",
        user_otp: ""
    });


    const getOTPAndSetNextStep = async (values) => {
        try {
            setGetOTPLoading(true)
            const res = await axios.post("/api/users/send-otp", values)
            const hashed_otp = res.data.hashed_otp

            setPayload({
                phone_number: values.phone_number,
                password: values.password,
                hashed_otp: hashed_otp,
            })

            setStep(2)
        } catch (error) {
            message.error("Invalid Credentials")
        } finally {
            setGetOTPLoading(false)
        }
    }

    const Login = async (values) => {
        const loginPayload = {
            phone_number: payload.phone_number,
            password: payload.password,
            user_otp: values.user_otp,
            hashed_otp: payload.hashed_otp
        }

        try {
            setLoginLoading(true)

            const response = await axios.post("/api/users/login/v2", loginPayload)

            localStorage.setItem('device_id', response.data.access_token);

            if (response.data.user.role === "admin") {
                authContext.setUser(response.data.user)
                authContext.setIsAuthenticated(true)
                history.push("/")
            } else {
                message.error("Unauthorized!")
                setStep(1)
            }
        } catch (error) {
            message.error("Invalid OTP! Try Again!")
            setStep(1)
        } finally {
            setLoginLoading(false)
        }
    }

    return (
        <AuthLayout>
            <Row gutter={[16, 16]} className="login__header">
                <Col span={4}>
                    <img
                        src="/logo.png"
                        width="100%"
                        alt="logo"
                    />
                </Col>
                <Col span={20}>
                    <h1>Sign in to CBIH</h1>
                    <span>Welcome back! Login to your backoffice</span>
                </Col>
            </Row>

            <br/>

            {step === 1 && (
                <>
                    <Form
                        layout="vertical"
                        onFinish={getOTPAndSetNextStep}
                    >
                        <Form.Item
                            label="Phone Number"
                            name="phone_number"
                            rules={[{required: true, message: 'Please input your phone number!'}]}
                        >
                            <Input
                                addonBefore="+88"
                                maxLength={11}
                                minLength={10}
                                type="number"
                                size={'large'}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password size={'large'}/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                block size="large"
                                htmlType="submit"
                                loading={getOTPLoading}
                                disabled={getOTPLoading}
                            >
                                Get OTP
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}

            {step === 2 && (
                <Form
                    layout="vertical"
                    onFinish={Login}
                >
                    <Form.Item
                        label="OTP"
                        name="user_otp"
                        rules={[{required: true, message: 'Please input OTP'}]}
                    >
                        <Input
                            type="number"
                            size={'large'}
                            inputMode={'numeric'}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" size="large" block htmlType="submit" loading={loginLoading} disabled={loginLoading}>
                            Log In
                        </Button>
                    </Form.Item>
                </Form>
            )}

        </AuthLayout>
    );
};

export default Login;
