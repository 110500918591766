/* eslint-disable import/no-anonymous-default-export */
import React, { createContext, useState, useEffect } from "react";

//importing api services
import { authenticateService } from "../api/auth";

//importing components
import Loader from "../Components/Library/Loader";

export const AuthContext = createContext();

export default ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        authenticateService().then((res) => {
            if (res.success) {
                const { response } = res;
                //checking user role
                if (response.data.role === "admin") {
                    //   console.log(`User authenticated`);
                    setIsAuthenticated(true);
                    setUser(response.data);
                    setIsLoaded(true);
                } else {
                    setIsLoaded(true);
                    setUser(null);
                }
            } else {
                setIsLoaded(true);
                setUser(null);
            }
        });
    }, []);

    return (
        <div>
            {!isLoaded ? (
                <Loader height={"100vh"} />
            ) : (
                <AuthContext.Provider
                    value={{ user, setUser, isAuthenticated, setIsAuthenticated }}
                >
                    {children}
                </AuthContext.Provider>
            )}
        </div>
    );
};
