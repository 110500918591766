import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination, Button, Avatar, Spin, Input, Tag, Form, Row, Col } from "antd";

// importing components
import Layout from "../../Components/Layout";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";

// importing styles
import styles from "./Merchants.module.scss";

// importing axios
import axios from "../../config/axios";

//importing icons
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { GoCheck } from "react-icons/go";
import { IoClose } from "react-icons/io5";

const Merchants = () => {

    const [form] = Form.useForm();

    // states
    const [allMerchants, setAllMerchants] = useState([]);
    const [getAllMerchantsLoading, setGetAllMerchantsLoading] = useState(true);
    const [merchantCount, setMerchantCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [spin, setSpin] = useState(false);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        // get all merchants, access admin
        (async () => {
            setSpin(true);
            const res = await axios.get(`/api/admin/merchants`, {
                withCredentials: true,
                params: {
                    page: currentPage,
                    limit: 20,
                    search_term: filters.search_term ?? null,
                },
            });
            setMerchantCount(res.data.count);
            setAllMerchants(res.data.results);
            setSpin(false);
            setGetAllMerchantsLoading(false);
        })();

    }, [currentPage, filters]);

    return (
        <Layout pageName='Merchants'>
            <div className={styles.filter}>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={(values) => {
                        setCurrentPage(1);
                        setFilters(values);
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col lg={12} sm={24} xs={24}>
                            <Form.Item
                                name={'search_term'}
                                style={{ margin: 0 }}
                            >
                                <Input placeholder='Search By Merchant Name' />
                            </Form.Item>
                        </Col>

                        <Col lg={12} sm={24} xs={24}>
                            <Form.Item style={{ margin: 0 }}>
                                <div className={styles.actions_btn_wrapper}>
                                    <Button
                                        htmlType='submit'
                                        type='primary'
                                        icon={<SearchOutlined />}
                                    >
                                        Apply Filters
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setCurrentPage(1);
                                            form.resetFields();
                                            setFilters({});
                                        }}
                                        type='danger'
                                        icon={<CloseCircleOutlined />}
                                        disabled={filters.search_term ? false : true}
                                    >
                                        Clear Filters
                                    </Button>
                                </div>

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className={styles.merchant}>
                <h2 className='primary_h2'>Merchant Management</h2>
                {getAllMerchantsLoading ? (
                    <SimpleLoader height='100%' />
                ) : (
                    <>
                        <Spin spinning={spin} indicator={<SimpleLoader height='100%' />}>
                            <div className={styles.merchantTable}>
                                <table>
                                    <thead>
                                        <tr className='tr__head'>
                                            <th className='th'>Name</th>
                                            <th className='th'>Division</th>
                                            <th className='th'>Phone</th>
                                            <th className='th'>Representative Name</th>
                                            <th className='th'>Representative Number</th>
                                            <th className='th'>Logo</th>
                                            <th className='th'>Reward Point</th>
                                            <th className='th'> Active </th>
                                            <th className='th'> Actions </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allMerchants.map((merchant, index) => (
                                            <tr className='tr' key={index}>
                                                <td className='td'> {merchant.venture_name} </td>
                                                <td className='td'>{merchant.division}</td>
                                                <td className='td'>
                                                    <a href={`tel:${merchant.contact_number}`}>{merchant.contact_number}</a>
                                                </td>
                                                <td className='td'>{merchant.user_account.name}</td>
                                                <td className='td'>
                                                    <a href={`tel:${merchant.user_account.phone_number}`}>{merchant.user_account?.phone_number}</a>
                                                </td>
                                                <td className='td'>
                                                    <Avatar src={merchant.logo} alt='logo' size={55} shape='square' />
                                                </td>
                                                <td className='td'>
                                                    <Tag color='#000'>{merchant.user_account.reward_points}</Tag>
                                                </td>

                                                <td className='td'>
                                                    {merchant.is_active ?
                                                        <GoCheck size={20} color={"var(--color-green)"} />
                                                        :
                                                        <IoClose size={20} color={"var(--color-red)"} />
                                                    }
                                                </td>
                                                <td className='td'>
                                                    <Link to={`/merchants/${merchant.id}`}>
                                                        <Button type='primary'>Details</Button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='sh__pagination__center'>
                                <Pagination
                                    showTotal={(total) => `Total ${total} merchants`}
                                    total={merchantCount}
                                    onChange={(page) => setCurrentPage(page)}
                                    defaultPageSize={20}
                                    current={currentPage}
                                />
                            </div>
                        </Spin>
                    </>
                )}
            </div>
        </Layout>
    );
};

export default Merchants;
