import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    Modal,
    Pagination,
    Popover,
    Row,
    Select,
    Spin,
    Tag,
    Tooltip,
    message,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import useSound from "use-sound";

//importing components
import Layout from "../../Components/Layout";
import NoData from "../../Components/Library/NoData";
import OrderIdWithCopy from "../../Components/OrderIdWithCopy/OrderIdWithCopy";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";

// import styles
import styles from "./Orders.module.scss";

//importing icons
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";

//importing utils
import { MdDirectionsBike } from "react-icons/md";
import { RiChat3Line, RiChatPrivateFill } from "react-icons/ri";
import ClickToCopyText from "../../Components/Library/ClickToCopyText";
import axios from "../../config/axios";
import { colorByStatus, formattedPrice } from "../../utils/helpers";
import nexaAlert from "../../utils/nexa_alert.mp3";
import useInterval from "../../utils/useInterval";
import OrderDetails from "../OrderDetails/OrderDetails";

// //importing excel export
// import ReactExport from 'react-export-excel';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Orders = () => {
    const [form] = Form.useForm();

    const [allOrders, setAllOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [refresh, setRefresh] = useState(null);
    const [spin, setSpin] = useState(false);
    const [limit, setLimit] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [exportData, setExportData] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);
    const [pathaoLoading, setPathaoLoading] = useState(false);
    const [pathaoConModalOpen, setPathaoConModalOpen] = useState(false);
    const [pathaoConOrder, setPathaoConOrder] = useState({});

    const [playAlert] = useSound(nexaAlert);

    const showTotal = (total) => {
        return `Total ${total} Sales Data`;
    };

    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    // track page changes
    function trackPage(page) {
        window.scrollTo(0, 0);
        setCurrentPage(page);
    }

    //   get all orders from api
    const getOrders = async (decLimit = null) => {
        const res = await axios.get("/api/orders", {
            withCredentials: true,
            params: {
                page: currentPage,
                limit: decLimit ? decLimit : limit,
                order_id: filters.order_id ?? null,
                phone_number: filters.phone_number ?? null,
                customer_name: filters.customer_name ?? null,
                product_name: filters.product_name ?? null,
                status: filters.status ?? null,
                start: filters.range ? moment(filters.range[0]).format("YYYY-MM-DD") : null,
                end: filters.range ? moment(filters.range[1]).format("YYYY-MM-DD") : null,
            },
        });

        return res.data;
    };

    const refreshOrders = () => {
        (async () => {
            let data = await getOrders();
            setTotalOrders(data.count);
            setAllOrders(data.results);
        })();
    };

    useEffect(() => {
        (async () => {
            setSpin(true);
            let data = await getOrders();
            setTotalOrders(data.count);
            setAllOrders(data.results);
            setSpin(false);
            setLoading(false);
        })();
        // eslint-disable-next-line
    }, [refresh, currentPage, limit, filters]);

    // alert on new orders
    useInterval(async () => {
        setSpin(true);
        const data = await getOrders();

        if (data.count > totalOrders) {
            playAlert();
            message.success(`${data.count - totalOrders} New order found. Check it out.`);
            setTotalOrders(data.count);
            setAllOrders(data.results);
        }

        setSpin(false);
    }, 60000);

    //defining assign ride function
    const assignRider = (order_id, rider) => {
        axios.patch(`/api/orders/mod/${order_id}`, { rider: rider }, { withCredentials: true }).then(() => {
            message.success("Rider assigned successfully!");
            setRefresh(Math.random());
            let fil = filters;
            fil.rider = rider;
            setFilters(fil);
        });
    };

    //defining excelExport function
    const excelExport = async () => {
        setExportLoading(true);
        let data = await getOrders(1000);
        console.log(data.results);
        let processedData = [];
        data.results.forEach((order) => {
            order.cus_name = order.customer.name;
            order.cus_phone_number = order.customer.phone_number;
            order.cus_address = `${order.delivery_address.address}, ${order.delivery_address.area}`;
            let items = "";
            order.order_items.forEach((item, index) => {
                if (order.order_items.length === 0 && index === 0) {
                    items = items + item.name + "-" + item.sku + " (" + item.quantity + " unit)";
                } else {
                    items = items + item.name + "-" + item.sku + " (" + item.quantity + " unit), ";
                }
            });
            order.order_items_str = items;
            order.schedule = order.scheduled_date ? `${order.scheduled_date} (${order.scheduled_time})` : "N/A";
            processedData.push(order);
        });
        setExportData(processedData);
        setExportLoading(false);
    };

    const placeConsignmentOnPathao = (orderID) => {
        setPathaoLoading(orderID);
        axios
            .post(`/api/orders/deliveries/pathao/consignment/${orderID}`, {}, { withCredentials: true })
            .then((response) => {
                message.success(response.data.msg);
                setRefresh(Math.random());
            })
            .catch((err) => {
                message.error(err.response.data.msg);
            })
            .finally(() => {
                setPathaoLoading(null);
            });
    };

    return (
        <Layout pageName={"Order Management"}>
            <div className="filter">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        setCurrentPage(1);
                        setFilters(values);
                    }}
                    style={{ width: "100%" }}
                >
                    <Row gutter={[16, 16]}>
                        <Col lg={6} sm={24} xs={24}>
                            <Form.Item name={"order_id"} style={{ margin: 0 }}>
                                <Input placeholder="Search by Order ID" />
                            </Form.Item>
                        </Col>
                        <Col lg={6} sm={24} xs={24}>
                            <Form.Item name={"phone_number"} style={{ margin: 0 }}>
                                <Input type={"number"} maxLength={11} placeholder="Search by Phone Number" />
                            </Form.Item>
                        </Col>
                        <Col lg={6} sm={24} xs={24}>
                            <Form.Item name={"customer_name"} style={{ margin: 0 }}>
                                <Input type={"text"} placeholder="Search by Customer Name" />
                            </Form.Item>
                        </Col>
                        <Col lg={6} sm={24} xs={24}>
                            <Form.Item name={"range"} style={{ margin: 0 }}>
                                <DatePicker.RangePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col lg={6} sm={24} xs={24}>
                            <Form.Item name={"status"} style={{ margin: 0 }}>
                                <Select placeholder="Filter by Status">
                                    <Select.Option value={"pending"}>Pending</Select.Option>
                                    <Select.Option value={"processing"}>Processing</Select.Option>
                                    <Select.Option value={"in route"}>In Route</Select.Option>
                                    <Select.Option value={"delivered"}>Delivered</Select.Option>
                                    <Select.Option value={"cancelled"}>Cancelled</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {/*<Col lg={6} sm={24} xs={24}>*/}
                        {/*    <Form.Item name={'product_name'} style={{margin: 0}}>*/}
                        {/*        <Input type={'text'} placeholder="Search by Product Name"/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col lg={12} sm={24} xs={24}>
                            <Form.Item style={{ margin: 0 }}>
                                <div className={styles.actions_btn_wrapper}>
                                    <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
                                        Apply Filters
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setCurrentPage(1);
                                            form.resetFields();
                                            setFilters({});
                                        }}
                                        type="danger"
                                        icon={<CloseCircleOutlined />}
                                    >
                                        Clear Filters
                                    </Button>
                                    {/*<Button*/}
                                    {/*    onClick={excelExport}*/}
                                    {/*    type="warning"*/}
                                    {/*    loading={exportLoading}*/}
                                    {/*>*/}
                                    {/*    Export Selection*/}
                                    {/*</Button>*/}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className="card">
                {/*<h2 className="primary_h2">Order Management</h2>*/}
                {loading ? (
                    <SimpleLoader height="70vh" />
                ) : (
                    <>
                        {allOrders.length > 0 ? (
                            <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                                <Checkbox.Group style={{ width: "100%" }} onChange={(selectedOrders) => console.log(selectedOrders)}>
                                    <div className="order_table" style={{ width: "100%" }}>
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr className="tr__head">
                                                    <th className="th">ID</th>
                                                    <th className="th">Order Time</th>
                                                    <th className="th">Customer</th>
                                                    <th className="th">Total</th>
                                                    <th className="th">Status</th>
                                                    <th className="th">Payment</th>
                                                    <th className="th">Notes</th>
                                                    <th className="th">Delivery</th>
                                                    <th className="th"> Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {allOrders.map((order, index) => (
                                                    <tr className="tr" key={index}>
                                                        <td className="td">
                                                            <OrderIdWithCopy id={order.id} />
                                                        </td>
                                                        <th className="th">
                                                            <div className={"d-flex flex-column"}>
                                                                <span>{moment(order.created_at).format("MMMM D, YYYY")}</span>
                                                                <span>{moment(order.created_at).format("LT")}</span>
                                                            </div>
                                                        </th>
                                                        <td className="td">
                                                            <Popover content={<CustomerInfo id={order.customer.id} />}>
                                                                <div className="fc">
                                                                    <span>{order.customer.name}</span>
                                                                    <a className="primary-link" href={`tel:${order.customer.phone_number}`}>
                                                                        {order.customer.phone_number}
                                                                    </a>
                                                                </div>
                                                            </Popover>
                                                        </td>
                                                        <td className="td">
                                                            <Tag>{formattedPrice(order.total)}</Tag>
                                                        </td>
                                                        <td className="td">
                                                            <Tag
                                                                color={colorByStatus(order.status)}
                                                                style={{ textTransform: "capitalize" }}
                                                            >
                                                                {order.status}
                                                            </Tag>
                                                        </td>
                                                        <td className="td">
                                                            <Tag
                                                                color={colorByStatus(order.payment_status)}
                                                                style={{ textTransform: "capitalize" }}
                                                            >
                                                                {order.payment_status}
                                                            </Tag>
                                                        </td>
                                                        <td className="td">
                                                            <div className={"d-flex align-items-center gap-2"}>
                                                                <Tooltip
                                                                    title={
                                                                        order.private_notes
                                                                            ? "Private Notes: " + order.private_notes
                                                                            : "N/A"
                                                                    }
                                                                >
                                                                    <Button disabled={!order.private_notes}>
                                                                        <RiChatPrivateFill size={20} />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip title={order.notes ? "Public Notes: " + order.notes : "N/A"}>
                                                                    <Button disabled={!order.notes}>
                                                                        <RiChat3Line size={20} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                        <td className="td">
                                                            {order.delivery_tracking_code ? (
                                                                <div>
                                                                    <span style={{ textTransform: "capitalize" }}>
                                                                        {order.delivery_service}
                                                                    </span>
                                                                    <ClickToCopyText text={order.delivery_tracking_code} />
                                                                </div>
                                                            ) : (
                                                                <Button
                                                                    onClick={() => {
                                                                        setPathaoConOrder(order);
                                                                        setPathaoConModalOpen(true);
                                                                    }}
                                                                    disabled={
                                                                        pathaoLoading === order.id ||
                                                                        order.status === "cancelled" ||
                                                                        order.status === "delivered"
                                                                    }
                                                                    loading={pathaoLoading === order.id}
                                                                >
                                                                    <MdDirectionsBike size={20} />
                                                                </Button>
                                                            )}
                                                        </td>
                                                        <td className="td">
                                                            {/*<Link to={`/orders/${order.id}`} target="_blank">*/}
                                                            <Button onClick={() => setOrder(order)} type="primary">
                                                                Details
                                                            </Button>
                                                            {/*</Link>*/}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Checkbox.Group>

                                <div className="sh__pagination__center">
                                    <Pagination
                                        onShowSizeChange={onShowSizeChange}
                                        current={currentPage}
                                        onChange={trackPage}
                                        showTotal={showTotal}
                                        defaultPageSize={limit}
                                        total={totalOrders}
                                    />
                                </div>
                            </Spin>
                        ) : (
                            <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                                <NoData />
                            </Spin>
                        )}
                    </>
                )}
            </div>

            {/* Download Excel Component */}
            {/*{*/}
            {/*    exportData &&*/}
            {/*    <Modal*/}
            {/*        visible={exportData ? true : false}*/}
            {/*        onCancel={() => setExportData(null)}*/}
            {/*        footer={null}*/}
            {/*        centered={true}*/}
            {/*    >*/}
            {/*        <div style={{ height: 'fit-content' }}>*/}
            {/*            <ExcelFile*/}
            {/*                element={*/}
            {/*                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '4rem', paddingBottom: '4rem' }}>*/}
            {/*                        <Button type="primary">Download Excel File</Button>*/}
            {/*                    </div>*/}
            {/*                }*/}
            {/*                filename="Products"*/}
            {/*            >*/}
            {/*                <ExcelSheet data={exportData} name={'Orders'}>*/}
            {/*                    <ExcelColumn label="Order ID" value="id" />*/}
            {/*                    <ExcelColumn label="Customer Name" value="cus_name" />*/}
            {/*                    <ExcelColumn label="Phone Number" value="cus_phone_number" />*/}
            {/*                    <ExcelColumn label="Address" value="cus_address" />*/}
            {/*                    <ExcelColumn label="Scheduled Time" value="schedule" />*/}
            {/*                    <ExcelColumn label="Order Items" value="order_items_str" />*/}
            {/*                    <ExcelColumn ali label="Order Total" value="total" />*/}
            {/*                </ExcelSheet>*/}
            {/*            </ExcelFile>*/}
            {/*        </div>*/}
            {/*    </Modal>*/}
            {/*}*/}

            <Drawer
                open={!!order}
                width={"90vw"}
                onClose={() => {
                    setOrder(null);
                    refreshOrders();
                }}
            >
                {order && <OrderDetails orderID={order.id} />}
            </Drawer>

            <PathaoConModal
                open={pathaoConModalOpen}
                close={() => setPathaoConModalOpen(false)}
                order={pathaoConOrder}
                setRefresh={setRefresh}
            />
        </Layout>
    );
};

export default Orders;

const CustomerInfo = ({ id }) => {
    const [customerInfo, setCustomerInfo] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getUserInfo = async () => {
            setLoading(true);
            const res = await axios.get(`/api/users/${id}`, { withCredentials: true });
            setCustomerInfo(res.data);
            setLoading(false);
        };
        getUserInfo();
    }, [id]);
    return (
        <>
            {loading ? (
                <SimpleLoader width="100px" />
            ) : (
                <>
                    {customerInfo && (
                        <div style={{ textAlign: "center" }}>
                            {/*<Avatar src={customerInfo.dp} size={50}/>*/}
                            <h4 className={"text-success"}>{customerInfo.name}</h4>
                            <span> Phone: {customerInfo.phone_number} </span>
                            <br />
                            <span> Total Order: {customerInfo.order_count} </span>
                            <br />
                            <span> Delivered Order: {customerInfo.delivered_order_count} </span>
                            {/*<br/>*/}
                            {/*<span> Reward Points: {customerInfo.reward_points} </span>*/}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

const PathaoConModal = ({ open, close, order, setRefresh }) => {
    const [loading, setLoading] = useState({
        deliveryZones: true,
        submitConsignment: false,
    });
    const [deliveryZones, setDeliveryZones] = useState([]);
    const [selectedDeliveryZone, setSelectedDeliveryZone] = useState(null);

    useEffect(() => {
        if (!order.pathao_city_id) return;
        (async () => {
            const { data } = await axios.get(`/api/orders/deliveries/pathao/zones?city_id=${order.pathao_city_id}`, {
                withCredentials: true,
            });
            setDeliveryZones(data.results);
            setLoading((prev) => ({ ...prev, deliveryZones: false }));
        })();
    }, [order.pathao_city_id]);

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    // submit consignment
    const handleSubmitConsignment = async () => {
        if (!selectedDeliveryZone) return message.error("Please select a delivery zone");

        setLoading((prev) => ({ ...prev, submitConsignment: true }));

        try {
            const response = await axios.post(
                `/api/orders/deliveries/pathao/consignment/${order.id}`,
                {
                    pathao_zone_id: selectedDeliveryZone,
                },
                { withCredentials: true }
            );

            message.success(response.data.msg);
            setRefresh(Math.random());
            close();
        } catch (err) {
            message.error(err.response.data?.msg);
        } finally {
            setLoading((prev) => ({ ...prev, submitConsignment: false }));
        }
    };

    return (
        <Modal title="Set Pathao Delivery Zone" open={open} onCancel={close} footer={null} centered>
            <div>Order Id: {order.id}</div>
            <div>Address: {order.address}</div>
            <div>Order City: {order.city}</div>
            <div className="pt-3 pb-4">
                <div className="pb-2 text-success">Select Pathao Delivery Zone</div>
                <Select
                    showSearch
                    placeholder="Select Zone"
                    optionFilterProp="children"
                    onChange={(value) => setSelectedDeliveryZone(value)}
                    filterOption={filterOption}
                    className="w-100"
                    loading={loading.deliveryZones}
                    options={deliveryZones.map((zone) => ({
                        value: zone.zone_id,
                        label: zone.zone_name,
                    }))}
                />
            </div>

            <Button
                type="primary"
                block
                onClick={handleSubmitConsignment}
                loading={loading.submitConsignment}
                disabled={loading.submitConsignment}
            >
                Set Pathao Zone
            </Button>
        </Modal>
    );
};
