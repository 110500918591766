import { useContext } from "react";

//importing context
import { GlobalStates } from "../../Contexts/GlobalStates";

const SimpleLoader = ({ height, width, white }) => {
    const theme = useContext(GlobalStates);
    return (
        <div className="hv-center" style={{ width: width || "100%", height: height || "50px" }}>
            {white ? <div className={theme === 'dark' ? "spinner-black" : "spinner-white"}></div> : <div className={theme === 'dark' ? "spinner-white" : "spinner-black"}></div>}
        </div>
    );
};

export default SimpleLoader;
