import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";

// importing components
import { Button, Form, Row, Col, Input, Select, message } from "antd";
import Layout from "../../../Components/Layout";

// importing styles
import styles from "./EditBlog.module.scss";

// importing icons
import { ArrowLeftOutlined } from '@ant-design/icons';
import { uploadImage } from "../../../api/fundamentals";
import SimpleLoader from "../../../Components/SimpleLoader/SimpleLoader";
import ProductDescriptionEditor from "../../../Components/Products/ProductDescriptionEditor";
import axios from "../../../config/axios";

const EditBlog = ({ match }) => {
    const history = useHistory()
    const [body, setBody] = useState("");
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [blogData, setBlogData] = useState({});
    const [loading, setLoading] = useState(true);
    const [updateBlogLoading, setUpdateBlogLoading] = useState(false);


    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 750, 250).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    useEffect(() => {
        const getBlogDetailsById = async () => {
            const res = await axios.get(`/api/blogs/${match.params.id}`, { withCredentials: true })
            const blog = res.data;
            setBlogData({
                title: blog.title,
                meta_description: blog.meta_description,
                tags: blog.tags.split(","),
            });
            setImages([blog.featured_image])
            setBody(blog.body)
            setLoading(false)
        }
        getBlogDetailsById()
    }, [match.params.id]);

    const UpdateBlog = async (values) => {
        values.featured_image = images[0];
        values.body = body;
        values.tags = values.tags.join(",");
        console.log(values);

        setUpdateBlogLoading(true)
        await axios.put(`/api/blogs/mod/${match.params.id}`, values, { withCredentials: true })
        message.success("Updated Blog Successfully!")
        setUpdateBlogLoading(false)

        history.push("/blogs")
    }

    return (
        <Layout pageName="Edit Blog">
            <div className="card">
                <div className={styles.edit_blog_top}>
                    <h2 className="primary_h2"> Edit Blog {match.params.id} </h2>
                    <Link to="/blogs">
                        <Button type="primary" icon={<ArrowLeftOutlined />}>
                            Back To Blogs
                        </Button>
                    </Link>
                </div>

                {loading ? (
                    <SimpleLoader height="100%" />
                ) : (
                    <div className={styles.create_blog_form}>
                        <Form
                            name="dynamic_form_nest_item"
                            onFinish={UpdateBlog}
                            autoComplete="off"
                            layout="vertical"
                            scrollToFirstError
                            initialValues={{
                                ...blogData
                            }}

                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <div className="uploadLogo">
                                        <label style={{ fontWeight: 600 }} htmlFor="logo">
                                            Featured Image
                                        </label>
                                        <div className="file-upload">
                                            <div className="image-upload-wrap">
                                                <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                                                <div className="drag-text">
                                                    {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}{" "}
                                                </div>
                                            </div>
                                            {images.length > 0 ? (
                                                <div style={{ marginTop: "2rem" }} className="h-center">
                                                    {images.map((image, index) => {
                                                        return <img
                                                            key={index}
                                                            className={styles.featured_image}
                                                            src={image}
                                                            alt="featured_image"
                                                        />;
                                                    })}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="title"
                                        label="Blog Title"
                                        rules={[{ required: true, message: "Add A Blog Title" }]}

                                    >
                                        <Input
                                            placeholder="How To Buy From Nexa"
                                            maxLength={245}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Meta Description for SEO"
                                        name="meta_description"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Add a Meta Description for SEO',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea showCount maxLength={155} rows={2} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Blog Body"
                                    >
                                        <ProductDescriptionEditor
                                            description={body}
                                            setDescription={setBody}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="tags"
                                        label="Tags (Type Appropriate Tags in Lowercase &amp; Press Enter If Not In List.)"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Add At Least One Tag",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="mobile,tech" mode="tags">
                                            <Select.Option value="mobile">
                                                mobile
                                            </Select.Option>
                                            <Select.Option value="tech">
                                                tech
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>


                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            loading={updateBlogLoading}
                                            size="medium"
                                            block
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Update This Blog
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                )}

            </div>
        </Layout>
    )
}

export default EditBlog;