import React, { useState, useEffect, useRef } from 'react';
import axios from "../../../config/axios";
import { useHistory } from "react-router-dom";
import styles from './StockIn.module.scss'

// importing components
import WarehouseLayout from "../../../Components/WarehouseLayout/WarehouseLayout";
import { Row, Col, Select, Modal, message, Button } from "antd";
import StockInProduct from "../../../Components/Warehouse/StockInProduct";

const { Option } = Select;



const StockIn = () => {
    const history = useHistory()
    const [products, setProducts] = useState([]);

    const [allWarehousesForSelect, setAllWarehousesForSelect] = useState([]);
    const [selectWarehouseModal, setSelectWarehouseModal] = useState(false);
    const [warehouseSelected, setWarehouseSelected] = useState({
        name: '',
        id: ''
    });
    const [allRacksForSelectedWarehouse, setAllRacksForSelectedWarehouse] = useState([]);
    const inputRef = useRef(null);

    const [refresh, setRefresh] = useState(null);
    const [cherryPopped, setCherryPopped] = useState(false);
    const [focused, setFocused] = useState(false);
    const [addStockInLoading, setAddStockInLoading] = useState(false);

    const onFocus = () => {
        setFocused(true)
    }
    const onBlur = () => {
        setFocused(false)
    }

    // console.log(products);

    useEffect(() => {
        if (cherryPopped) {
            window.localStorage.setItem(["stock-in"], JSON.stringify(products))
        } else {
            const stored = JSON.parse(window.localStorage.getItem("stock-in"));
            if (stored && stored.length && stored[0].warehouse_name && stored[0].warehouse) {
                setSelectWarehouseModal(false)
                setWarehouseSelected({
                    name: stored[0].warehouse_name,
                    id: stored[0].warehouse
                })
                getAllRackForSelectedWarehouse(stored[0].warehouse);

                setProducts(stored);
            } else {
                setSelectWarehouseModal(true)
            }

            setCherryPopped(true);
        }
    }, [products, refresh]);



    useEffect(() => {
        inputRef.current.focus()

        const getAllWarehouse = async () => {
            const res = await axios.get('/api/warehouses', { withCredentials: true })
            setAllWarehousesForSelect(res.data);
        }
        getAllWarehouse()
    }, []);

    // useEffect(() => {
    //     if (!focused) {
    //         console.log('not focused')
    //     }
    // }, [focused]);

    const getAllRackForSelectedWarehouse = async (id) => {
        const res = await axios.get(`/api/warehouses/racks`, {
            withCredentials: true, params: {
                warehouse: id
            }
        })
        setAllRacksForSelectedWarehouse(res.data)
    }



    const getProductsBySearch = async (searchTerm) => {
        if (!searchTerm) return;
        if (searchTerm.length < 9) return;

        inputRef.current.value = ""

        const res = await axios.get('/api/admin/products/search', {
            withCredentials: true, params: {
                all_merchant: true,
                query_term: searchTerm
            }
        })

        if (!res.data.length > 0) return;

        let item = { name: res.data[0].name, sku: res.data[0].sku, product: res.data[0].id }
        item.quantity = 1;
        item.warehouse = warehouseSelected.id;
        item.warehouse_name = warehouseSelected.name;

        if (products.filter(e => e.sku === item.sku).length > 0) {
            let index = products.findIndex(p => p.sku === item.sku);
            let updatedProducts = products;
            updatedProducts[index].quantity = updatedProducts[index].quantity + 1;
            setProducts([...updatedProducts])
        } else {
            setProducts(product => product.concat(item))
        }
    }

    // submit stock in to server
    const handleStockIn = async (values) => {
        setAddStockInLoading(true)
        await axios.post('/api/warehouses/stock-in', values, { withCredentials: true })
        setAddStockInLoading(false)
        message.success('New Stock Added')
        localStorage.removeItem('stock-in')
        history.push('/warehouses/stock-in')
    }


    return (
        <WarehouseLayout>
            <div className="card">
                {/* first select a warehouse name */}
                <Modal title="Select Warehouse"
                    visible={selectWarehouseModal}
                    footer={null}
                    onCancel={() => {
                        if (!warehouseSelected.name) {
                            message.error('Select Warehouse to proceed!')
                            history.push("/warehouses/stock-in")
                        }
                    }}
                    centered
                >
                    <Row>

                        <Col span={24}>
                            <Select placeholder="Select a Warehouse" style={{ width: "100%" }} onChange={(value, { name }) => {
                                setWarehouseSelected({
                                    name: name,
                                    id: value
                                })
                                getAllRackForSelectedWarehouse(value)
                                setSelectWarehouseModal(false)
                            }}
                            >
                                {allWarehousesForSelect?.map((option, index) => (
                                    <Option value={option.id} key={index} name={option.name}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Modal>

                {warehouseSelected.name && <h2 className={styles.h2_warehouse}>  Selected Warehouse: {warehouseSelected.name} </h2>}


                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <label htmlFor="barcode"> Scan Barcode </label>
                        <input
                            type="text"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            ref={inputRef}
                            className={focused ? styles.product_input : styles.product_input_red}
                            onChange={(e) => {
                                getProductsBySearch(e.target.value)
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    {products.length > 0 && (
                        <div className={styles.create_stock_in_table}>
                            <table>
                                <thead>
                                    <tr className="tr">
                                        <th className="th"> Sku </th>
                                        <th className="th"> Name </th>
                                        <th className="th"> Rack </th>
                                        <th className="th"> SubRack </th>
                                        <th className="th"> Quantity </th>
                                        <th className="th"> Remarks </th>
                                        <th className="th"> Remove </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {products.map((prod, index) => (
                                        <StockInProduct
                                            key={index}
                                            index={index}
                                            prod={prod}
                                            products={products}
                                            setRefresh={setRefresh}
                                            allRacksForSelectedWarehouse={allRacksForSelectedWarehouse}
                                            setProducts={setProducts}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            <Button
                                onClick={() => {
                                    handleStockIn(products);
                                }}
                                loading={addStockInLoading}
                                type="primary"
                                style={{ marginTop: '2rem' }}
                            >
                                Create New Stock-In
                            </Button>
                        </div>
                    )}
                </Row>
            </div>
        </WarehouseLayout>
    )
}

export default StockIn;