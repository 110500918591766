import { message, Tag } from "antd";

const OrderIdWithCopy = ({ id }) => {
    return (
        <Tag
            color="var(--color-dark)"
            style={{ cursor: 'pointer' }}
            title="Copy Order ID to Clipboard"
            onClick={() => {
                navigator.clipboard.writeText(id);
                message.success('Order ID Copied to clipboard!')
            }}
        >
            {id}
        </Tag>
    );
}

export default OrderIdWithCopy;