import { Button, Card, Drawer, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import NoData from "../Components/Library/NoData";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";
import axios from "../config/axios";
// import { PrinterOutlined } from "@ant-design/icons";

const B2BOrders = () => {
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [orderDetailsDrawerOpen, setOrderDetailsDrawerOpen] = useState(false);
    const [selectedOrderID, setSelectedOrderID] = useState(null);
    const [tableLoading, setTableLoading] = useState(false);

    // get all b2b orders
    useEffect(() => {
        (async () => {
            setTableLoading(true);
            const { results: orders, count } = (
                await axios.get("/api/admin/orders/b2b?limit=2", { withCredentials: true, params: { page, limit: 20 } })
            ).data;
            setOrders(orders);
            setCount(count);
            setLoading(false);
            setTableLoading(false);
        })();
    }, [page]);

    return (
        <Layout pageName="B2B Orders">
            {loading ? (
                <SimpleLoader height="80vh" />
            ) : count === 0 ? (
                <NoData message={"No B2B Orders Yet!"} height={"80vh"} />
            ) : (
                <>
                    <Card
                        bodyStyle={{
                            padding: "10px 15px",
                        }}
                        bordered={false}
                    >
                        <h4 className="mb-0">B2B Orders Management</h4>
                    </Card>

                    <div className="pt-4"></div>

                    <Table
                        dataSource={orders}
                        bordered
                        rowKey={(record) => record.id}
                        pagination={{
                            pageSize: 20,
                            total: count,
                            showSizeChanger: false,
                            current: page,
                            onChange: (page) => {
                                setPage(page);
                            },
                        }}
                        loading={tableLoading}
                        columns={[
                            {
                                title: "Name",
                                dataIndex: "name",
                                key: "name",
                            },
                            {
                                title: "Email",
                                dataIndex: "email",
                                key: "email",
                            },
                            {
                                title: "Phone Number",
                                dataIndex: "phone_number",
                                key: "phone_number",
                            },
                            {
                                title: "Address",
                                dataIndex: "address",
                                key: "address",
                            },
                            {
                                title: "Ordered Item",
                                dataIndex: "order_items",
                                key: "order_items",
                                render: (order_items) => {
                                    return order_items.length;
                                },
                            },
                            {
                                title: "Action",
                                dataIndex: "action",
                                key: "action",
                                render: (_, record) => {
                                    return (
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                setOrderDetailsDrawerOpen(true);
                                                setSelectedOrderID(record.id);
                                            }}
                                        >
                                            Details
                                        </Button>
                                    );
                                },
                            },
                        ]}
                    />

                    {/* Order Details Drawer */}
                    <OrderDetailsDrawer
                        orderID={selectedOrderID}
                        open={orderDetailsDrawerOpen}
                        close={() => setOrderDetailsDrawerOpen(false)}
                    />
                </>
            )}
        </Layout>
    );
};

export default B2BOrders;

const OrderDetailsDrawer = ({ orderID, open, close }) => {
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState({});

    useEffect(() => {
        (async () => {
            if (!orderID) return;
            setLoading(true);
            const { results: order } = (await axios.get(`/api/admin/orders/b2b/${orderID}`, { withCredentials: true })).data;
            setOrder(order);
            setLoading(false);
        })();
    }, [orderID]);

    return (
        <Drawer title="B2B Order Details" placement="right" onClose={() => close()} open={open} width={"80%"}>
            {loading ? (
                <SimpleLoader height={"80vh"} />
            ) : (
                <Card bodyStyle={{ padding: 10 }}>
                    <h3>Order Details</h3>

                    <div className={"d-block text-danger"}>
                        <b>Placed At:</b> {moment(order.created_at).format("MMMM D, YYYY - LT")}
                    </div>

                    <div className={"d-block text-primary"}>
                        <b>Last Update At:</b> {moment(order.updated_at).format("MMMM D, YYYY - LT")}
                    </div>

                    <div>
                        <b>Customer Name:</b> {order.name}
                    </div>

                    <div>
                        <b>Customer Email:</b> {order.email}
                    </div>

                    <div>
                        <b>Customer Phone Number:</b> {order.phone_number}
                    </div>

                    <div>
                        <b>Customer Address:</b> {order.address}
                    </div>

                    {/* <div className="pt-2">
                        <a href={`/b2b/invoice/${orderID}`} target="_blank" rel="noreferrer">
                            <Button type="primary" icon={<PrinterOutlined size={20} />}>
                                Print Invoice
                            </Button>
                        </a>
                    </div> */}

                    <div className="pt-4">
                        <h4 className="pb-2">Order Items & Quantity</h4>

                        <Table
                            dataSource={order.order_items}
                            rowKey={(record) => record.id}
                            bordered
                            pagination={false}
                            size="small"
                            columns={[
                                {
                                    title: "Product ID",
                                    dataIndex: "product",
                                    key: "product",
                                    render: (product) => product.id,
                                },
                                {
                                    title: "Image",
                                    dataIndex: "product",
                                    key: "product",
                                    render: (product) => {
                                        return <img src={product.featured_image} alt={product.name} height={50} />;
                                    },
                                },
                                {
                                    title: "Name",
                                    dataIndex: "product",
                                    key: "product",
                                    render: (product) => product.name,
                                },
                                {
                                    title: "Quantity",
                                    dataIndex: "quantity",
                                    key: "quantity",
                                },
                                {
                                    title: "Price (BDT)",
                                    dataIndex: "product",
                                    key: "product",
                                    render: (product) => product.discounted_price ?? product.regular_price,
                                },
                                {
                                    title: "Sub Total (BDT)",
                                    dataIndex: "product",
                                    key: "product",
                                    render: (product, record) => record.quantity * (product.discounted_price ?? product.regular_price),
                                },
                            ]}
                        />
                    </div>
                </Card>
            )}
        </Drawer>
    );
};
