import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//importing components
import { Col, Drawer, Input, Popover, Row } from "antd";
import Sidebar from "./Layout/Sidebar";

//importing icons
import { BiMenuAltLeft } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

//importing context api
import { AuthContext } from "../Contexts/AuthContext";
import { GlobalStates } from "../Contexts/GlobalStates";

//importing helper function
import { shortNameGenerator } from "../utils/helpers";

const Layout = (props) => {
    const [drawerMobileVisible, setDrawerMobileVisible] = useState(false);

    const history = useHistory();
    // const { switcher, themes } = useThemeSwitcher();
    const { user, setUser } = useContext(AuthContext);
    const { theme, setTheme } = useContext(GlobalStates);

    const [dropDownMenuVisibility, setDropDownMenuVisibility] = useState(false);
    const [orderSearch, setOrderSearch] = useState(null);

    useEffect(() => {
        const currentTheme = localStorage.getItem("theme") ? localStorage.getItem("theme") : null;
        if (currentTheme) {
            document.documentElement.setAttribute("data-theme", currentTheme);

            if (currentTheme === "dark") {
                setTheme("dark");
            } else {
                setTheme("light");
            }
        }
    }, [setTheme]);

    // useEffect(() => {
    //     // console.log('theme is: ', theme);
    //
    //     if (theme === 'dark') {
    //         // console.log('setting dark theme')
    //         switcher({ theme: themes.dark });
    //     } else {
    //         // console.log('setting light theme')
    //         switcher({ theme: themes.light });
    //     }
    // }, [theme]);

    //defining dropDownMenuVisibilityHandler function
    const dropDownMenuVisibilityHandler = (value) => {
        setDropDownMenuVisibility(value);
    };

    //defining toggleDarkMode function
    // const toggleDarkMode = checked => {
    //     if (checked) {
    //         document.documentElement.setAttribute('data-theme', 'dark');
    //         localStorage.setItem('theme', 'dark'); //add this
    //         setTheme('dark');
    //     } else {
    //         document.documentElement.setAttribute('data-theme', 'light');
    //         localStorage.setItem('theme', 'light'); //add this
    //         setTheme('light');
    //     }
    // }

    return (
        <>
            <div className="main">
                {/* Sidebar Component */}
                <Sidebar setUser={setUser} theme={theme} mobile={false} />

                <div className="main-content">
                    <header>
                        {/* partitioning the header into 3 columns */}
                        <Row className="fw">
                            <Col span={10}>
                                {/* mobile menu that display only below 576px */}
                                <div className="header_mobile_menu" onClick={() => setDrawerMobileVisible(true)}>
                                    <div className="header_mobile_menu_icon">
                                        <BiMenuAltLeft size={25} />
                                    </div>
                                    <div className="header_mobile_menu_logo">
                                        <img src="/logo.png" width={50} style={{ marginLeft: "1rem" }} alt="logo-cbih" />
                                    </div>
                                </div>

                                {/* drawer for mobile devices */}
                                <Drawer
                                    placement="left"
                                    closable={false}
                                    onClose={() => setDrawerMobileVisible(false)}
                                    open={drawerMobileVisible}
                                    key="left"
                                    width={270}
                                    bodyStyle={{ padding: 0 }}
                                >
                                    <Sidebar setUser={setUser} theme={theme} mobile={true} />
                                </Drawer>

                                <div className="header-navigation">
                                    <div className="header-navigation__arrows v-center hover-effect">
                                        <IoIosArrowBack
                                            onClick={() => {
                                                window.history.back();
                                            }}
                                            size={30}
                                        />
                                        <IoIosArrowForward
                                            onClick={() => {
                                                window.history.forward();
                                            }}
                                            size={30}
                                        />
                                    </div>
                                    <div className="header-navigation__breadcrumb v-center">
                                        <span>Admin Dashboard - </span>
                                        <p> {props.pageName} </p>
                                    </div>
                                </div>
                            </Col>

                            <Col style={{ display: "flex", justifyContent: "flex-end" }} span={14}>
                                <div className="hv-center header_search_form">
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            history.push(`/orders/${orderSearch}`);
                                        }}
                                    >
                                        <Input
                                            onChange={(event) => setOrderSearch(event.target.value)}
                                            placeholder="Search orders by ID"
                                            prefix={<FiSearch size={20} style={{ marginRight: "1rem" }} />}
                                            bordered={false}
                                            // style={{ fontSize: '1.4rem' }}
                                        />
                                    </form>
                                </div>

                                <div className="user-box">
                                    {user.dp ? (
                                        <div className="user-box__sign hv-center" style={{ backgroundColor: "transparent" }}>
                                            <img src={user.dp} width="50px" alt="" />
                                        </div>
                                    ) : (
                                        <div className="user-box__sign hv-center">{shortNameGenerator(user.name)}</div>
                                    )}
                                    <div className="user-box__name">
                                        <h2 title={user.name}>{user.name.split(" ")[0]}</h2>
                                        <span>{user.phone_number}</span>
                                    </div>
                                    <div className="hv-center">
                                        {/* Dropdown Menu */}
                                        <Popover
                                            trigger="click"
                                            open={dropDownMenuVisibility}
                                            placement="bottom"
                                            onOpenChange={dropDownMenuVisibilityHandler}
                                            content={<>Welcome {user.name}</>}
                                        >
                                            {/* <MdKeyboardArrowDown className="hover-effect" color="var(--color-grey-3)" size={20} /> */}
                                        </Popover>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </header>
                    <div style={{ margin: "2rem" }} className="main-body">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
