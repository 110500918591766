import React from "react";

//importing components
import { Row, Col } from "antd";

const AuthLayout = (props) => {
    return (
        <div className="auth">
            <Row>
                <Col
                    lg={{ span: 24 }}
                    sm={{ span: 24 }}
                >
                    <div className="card">
                        {props.children}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default AuthLayout;
