import { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from "../../config/axios";

// importing components
import Layout from "../../Components/Layout";
import { Button, Form, Input, message, Select } from "antd";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";

// importing helpers
import { uploadImage } from "../../api/fundamentals";


const { Option } = Select;



const CreateEmployee = () => {
    let history = useHistory();
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [addEmployeeLoading, setAddEmployeeLoading] = useState(false);

    const [form] = Form.useForm();

    //defining imageUploadHandler function
    const imageUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 400, 400).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages([response.data.secure_url]);
                    setXhr(false);
                }
            });
        }
    };

    const AddNewEmployee = async (values) => {
        if (images.length < 1) {
            message.error("Pleas­e Upload Profile Picture");
            return;
        }
        if (images.length > 1) {
            message.error("Pleas­e Upload Only One Image!");
            return;
        }

        values.dp = images[0];

        setAddEmployeeLoading(true)
        await axios.post('/api/employees', values, { withCredentials: true })
        message.success("Added New Employee Profile")
        setAddEmployeeLoading(false)
        history.push("/hr")
    }

    return (
        <Layout pageName="Create Employee">
            <div className="card">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <h2 className="primary_h2"> Create New Employee Profile</h2>
                    <Button type="primary" onClick={() => history.push("/hr")}>
                        Back To All Employee
                    </Button>
                </div>
                <Form name="AddNewEmployee" form={form} onFinish={AddNewEmployee} layout="vertical">
                    <div className="uploadLogo">
                        <label style={{ fontWeight: 600 }} htmlFor="logo">
                            Profile Picture
                        </label>
                        <div className="file-upload">
                            <div className="image-upload-wrap">
                                <input className="file-upload-input" type="file" multiple={false} onChange={imageUploadHandler} />
                                <div className="drag-text">
                                    {xhr === "image" ? <SimpleLoader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}{" "}
                                </div>
                            </div>
                            {images.length > 0 ? (
                                <div style={{ marginTop: "2rem" }} className="h-center">
                                    {images.map((image, index) => {
                                        return <img key={index} width="200px" height="200px" src={image} alt="logo" />;
                                    })}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <br />

                    <Form.Item label="Employee Name" name="name" rules={[{ required: true, message: "Please Add Employee Name" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Employee ID" name="employee_id" rules={[{ required: true, message: "Please Add Employee ID" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Fixed ID" name="fixed_id" rules={[{ required: true, message: "Please Add Fixed ID" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="category"
                        label="Employee Category"
                        rules={[
                            {
                                required: true,
                                message: "Select Employee Category",
                            },
                        ]}
                    >
                        <Select placeholder="Select a Category">
                            <Option value="">
                                Select a Category
                            </Option>
                            <Option value="tier-1">
                                Tier 1
                            </Option>
                            <Option value="admin-support">
                                Admin and Support
                            </Option>
                            <Option value="procurement">
                                Procurement
                            </Option>
                            <Option value="operation">
                                Operation
                            </Option>
                            <Option value="business_development">
                                Business Development
                            </Option>
                            <Option value="accounting">
                                Accounting
                            </Option>
                            <Option value="warehouse">
                                Warehouse
                            </Option>
                            <Option value="marketing">
                                Marketing
                            </Option>
                            <Option value="sales">
                                Sales
                            </Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Designation" name="designation" rules={[{ required: true, message: "Please Add Designation" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please Add Email" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Phone Number" name="phone_number" rules={[{ required: true, message: "Please Add Phone Number" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={addEmployeeLoading}>
                            Add New Employee Profile
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    );
}

export default CreateEmployee;