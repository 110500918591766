import { useContext, useEffect, useState } from 'react';
import moment from '../../config/moment';
import axios from '../../config/axios';
import styles from './Attendance.module.scss';

//importing components
import Layout from '../../Components/Layout';
import { Calendar, message, Badge } from 'antd';

//importing icons
import { GrLocation } from 'react-icons/gr';
import { AiOutlineClockCircle } from 'react-icons/ai';

//importing context
import { AuthContext } from '../../Contexts/AuthContext';

const Attendance = () => {
    const { user } = useContext(AuthContext);
    const currentDate = moment(new Date());
    const [userLoc, setUserLoc] = useState({
        latitude: null,
        longitude: null
    });
    const [refresh, setRefresh] = useState(null);
    const [attendances, setAttendances] = useState([]);

    useEffect(() => {

        //getting todays attendance if submitted
        axios.get(`/api/employees/attendance/month-wise?date=${moment(new Date()).format('YYYY-MM-DD')}&employee_id=${user.id}`, { withCredentials: true }).then(response => {
            console.log(response.data);
            setAttendances(response.data);

        });

        navigator.geolocation.getCurrentPosition(position => {
            // console.log(position);
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;

            setUserLoc({
                latitude: latitude,
                longitude: longitude
            });
        })
    }, [refresh]);

    //defining submitAttendance function
    const submitAttendance = async () => {
        try {

            let response = await axios.get(`https://us1.locationiq.com/v1/reverse.php?key=pk.cba93b8f8bcd2db8d5c28e0f200c75ef&lat=${userLoc.latitude}&lon=${userLoc.longitude}&format=json`)
            // console.log(response.data)
            let payload = {
                latitude: parseFloat(userLoc.latitude).toFixed(6),
                longitude: parseFloat(userLoc.longitude).toFixed(6),
                location_name: response.data.display_name.substring(0, 250)
            }

            await axios.post('/api/employees/attendance/submit', payload, { withCredentials: true });
            message.success('Attendance submitted successfully!');
            setRefresh(true);
        } catch (error) {
            if (error.response.status === 406) {
                message.error('Attendance already submitted for today!');
            }
        }
    }

    function dateCellRender(value) {
        // console.log(value.date());
        let index = attendances.findIndex(att => moment(att.timestamp).format('DD') == value.date())

        // console.log(index);
        if (index >= 0) {
            // console.log(moment(attendances[index].timestamp).format('MM'), value.month())
            if (parseInt(moment(attendances[index].timestamp).format('MM')) !== value.month()) {
                return (
                    <ul className="events">
                        <li >
                            <div className={styles.info}>
                                <AiOutlineClockCircle size={15} />
                                <span>
                                    {moment(attendances[index].timestamp).format('LT')}
                                </span>
                            </div>
                            <div className={styles.info}>
                                <GrLocation size={15} />
                                <span>
                                    {attendances[index].location_name}
                                </span>
                            </div>
                        </li>
                    </ul>
                );
            }

        } else {
            return (<></>);
        }

    }

    return (
        <Layout
            pageName='Attendance'
        >
            <div className='card'>
                <h1 style={{ fontSize: '1.6rem' }}>Attendance Management System</h1>
                <Calendar
                    value={currentDate}
                    disabledDate={(date) => {
                        if (date.endOf('d').valueOf() < currentDate || date.endOf('d').valueOf() > currentDate) {
                            return true;
                        }
                        return false;
                    }}
                    onSelect={submitAttendance}
                    dateCellRender={dateCellRender}
                />
            </div>
        </Layout>
    )
}

export default Attendance;