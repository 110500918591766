import { useState, useEffect } from "react";
import axios from "../config/axios";

//importing components
import Layout from "../Components/Layout";
import { Pagination, Input, Switch, Spin, Select, Button, message } from "antd";
import NoData from "../Components/Library/NoData";
import SimpleLoader from "../Components/Library/SimpleLoader";
import ProductItem from "../Components/Products/ProductItem/ProductItem";
import SkuWithCopy from "../Components/SkuWithCopy/SkuWithCopy";

//importing icons
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { SiMicrosoftexcel } from 'react-icons/si';
import { SaveOutlined, SyncOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

//importing utils
const {Option} = Select;

const ViewAllProducts = () => {
    const [searchTerm, setSearchTerm] = useState(null);
    const [skuTerm, setSkuTerm] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [searchButtonClicked, setSearchButtonClicked] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isVisible, setIsVisible] = useState(false);
    const [flashSale, setFlashSale] = useState(false);
    const [trait, setTrait] = useState('');

    return (
        <Layout pageName="Products Management">
            <div style={{paddingTop: 0}}>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{content: ''}}>
                    </div>
                    <Link to={'/create-product'}>
                        <Button type={'primary'}>Create Product</Button>
                    </Link>
                </div>
                <ProductFilter
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    setSearchButtonClicked={setSearchButtonClicked}
                    skuTerm={skuTerm}
                    setSkuTerm={setSkuTerm}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    selectedSubCategory={selectedSubCategory}
                    setSelectedSubCategory={setSelectedSubCategory}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    flashSale={flashSale}
                    setFlashSale={setFlashSale}
                    trait={trait}
                    setTrait={setTrait}
                />
            </div>

            <div className="sh__viewAllProducts">
                <ProductTable
                    searchTerm={searchTerm}
                    skuTerm={skuTerm}
                    selectedCategory={selectedCategory}
                    selectedSubCategory={selectedSubCategory}
                    searchButtonClicked={searchButtonClicked}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isVisible={isVisible}
                    flashSale={flashSale}
                    trait={trait}
                />
            </div>

        </Layout>
    );
};

export default ViewAllProducts;

// sub components

// product filterOption
const ProductFilter = ({
                           searchTerm,
                           setSearchTerm,
                           skuTerm,
                           setSkuTerm,
                           setSearchButtonClicked,
                           selectedCategory,
                           setSelectedCategory,
                           selectedSubCategory,
                           setSelectedSubCategory,
                           setSelectedSubSubCategory,
                           currentPage,
                           setCurrentPage,
                           isVisible,
                           setIsVisible,
    flashSale, setFlashSale, trait, setTrait
                       }) => {
    const [categories, setCategories] = useState([]);
    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState([]);

    useEffect(() => {
        const getAllCategories = async () => {
            const res = await axios.get('/api/public/categories', {withCredentials: true});
            setCategories(res.data);
        };
        getAllCategories();
    }, []);

    // get sub categories based on selected category
    const getSubCategoryByCategory = async (values) => {
        const res = await axios.get(`/api/public/subcategories?category=${values}`);
        setSubCategoriesByCategory(res.data);
    };


    return (
        <>

            <div className="filter" style={{marginBottom: '0rem'}}>
                <div className="flex_grow_full">
                    <Input placeholder="Enter Product Name" value={searchTerm}
                           onChange={(e) => setSearchTerm(e.target.value)}/>
                </div>

                <div className="flex_grow_full">
                    <Input placeholder="Enter Product SKU" value={skuTerm}
                           onChange={(e) => setSkuTerm(e.target.value)}/>
                </div>

                <div className="flex_grow_full">
                    <Select
                        style={{width: "100%"}}
                        value={selectedCategory}
                        onChange={(value) => {
                            setSelectedSubCategory("");
                            setSelectedCategory(value);
                            getSubCategoryByCategory(value);
                        }}
                    >
                        <Option value="" hidden>
                            Search By Category
                        </Option>
                        {categories.map((option, index) => (
                            <Option value={option.id} key={index}>
                                {option.name}
                            </Option>
                        ))}
                    </Select>
                </div>

                <div className="flex_grow_full">
                    <Select
                        style={{width: "100%"}}
                        value={selectedSubCategory}
                        onChange={(value) => {
                            setSelectedSubCategory(value)
                        }}
                    >
                        <Option value="" hidden>
                            Search By Sub Category
                        </Option>
                        {subCategoriesByCategory.map((option, index) => (
                            <Option value={option.id} key={index}>
                                {option.name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="flex_grow_full">
                    <Select
                        style={{width: "100%"}}
                        value={trait}
                        onChange={(value) => {
                            setTrait(value)
                        }}
                    >
                        <Option value="" hidden>
                            Search by Trait
                        </Option>
                        <Option value={'bestseller'}>Bestseller</Option>
                        <Option value={'featured'}>Featured</Option>
                    </Select>
                </div>


                <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <Switch
                        checkedChildren="Live Products"
                        unCheckedChildren="Live Products"
                        size="default"
                        checked={isVisible}
                        onChange={(checked) => setIsVisible(checked)}
                    />
                </div>

                <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <Switch
                        checkedChildren="Flash Sale"
                        unCheckedChildren="Flash Sale"
                        size="default"
                        checked={flashSale}
                        onChange={(checked) => setFlashSale(checked)}
                    />
                </div>

                <div>
                    <Button
                        danger={true}
                        style={{
                            backgroundColor: "var(--color-dark-light)",
                            color: searchTerm || skuTerm || selectedCategory || selectedSubCategory ? "red" : null,
                        }}
                        disabled={searchTerm || skuTerm || selectedCategory || isVisible || flashSale || trait ? false : true}
                        className="filter__item__button"
                        onClick={() => {
                            setSearchTerm(null);
                            setSkuTerm(null);
                            setSelectedCategory("");
                            setSelectedSubCategory("");
                            setCurrentPage(1);
                            setSearchButtonClicked(Math.random());
                            setIsVisible(false);
                            setFlashSale(false);
                            setTrait("");
                        }}
                        icon={<CloseCircleOutlined/>}
                    >
                        Clear Filter
                    </Button>
                </div>


                <div>
                    <Button
                        style={{backgroundColor: "var(--color-dark)", color: "var(--color-light)"}}
                        disabled={searchTerm || skuTerm || selectedCategory || selectedSubCategory || isVisible || flashSale || trait ? false : true}
                        onClick={() => {
                            setCurrentPage(1);
                            setSearchButtonClicked(Math.random());
                        }}
                        className="filter__item__button"
                        icon={<SearchOutlined/>}
                    >
                        Filter
                    </Button>
                </div>
            </div>
            {/*<div className="filter" style={{marginTop: "1rem"}}>*/}
            {/*    <Button*/}
            {/*        style={{*/}
            {/*            backgroundColor: "var(--color-dark)",*/}
            {/*            color: "var(--color-light)",*/}
            {/*            display: 'flex',*/}
            {/*            alignItems: 'center'*/}
            {/*        }}*/}
            {/*        disabled={!selectedCategory ? true : false}*/}
            {/*        className="filter__item__button"*/}
            {/*        icon={<SiMicrosoftexcel style={{marginRight: '1rem'}}/>}*/}
            {/*    >*/}
            {/*        <a style={{marginLeft: "", marginRight: "0", color: 'var(--color-light)'}}*/}
            {/*           href={`/products/export?category=${selectedCategory}&subcategory=${selectedSubCategory}&is_visible=${isVisible}`}*/}
            {/*           target="_blank" rel="noreferrer">*/}
            {/*            Export Current Selection*/}
            {/*        </a>*/}
            {/*    </Button>*/}
            {/*    <Button*/}
            {/*        style={{*/}
            {/*            backgroundColor: "var(--color-dark)",*/}
            {/*            color: "var(--color-light)",*/}
            {/*            display: 'flex',*/}
            {/*            alignItems: 'center'*/}
            {/*        }}*/}
            {/*        disabled={!selectedCategory ? true : false}*/}
            {/*        className="filter__item__button"*/}
            {/*        icon={<SiMicrosoftexcel style={{marginRight: '1rem'}}/>}*/}
            {/*    >*/}
            {/*        <a style={{marginLeft: "", marginRight: "0", color: 'var(--color-light)'}}*/}
            {/*           href={`/products/export?category=${selectedCategory}&subcategory=${selectedSubCategory}&is_visible=${isVisible}&type=fb_catalogue`}*/}
            {/*           target="_blank" rel="noreferrer">*/}
            {/*            Export Current Selection for FB Catalogue*/}
            {/*        </a>*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </>
    );
};

// product table
const ProductTable = ({
                          searchTerm,
                          skuTerm,
                          selectedCategory,
                          selectedSubCategory,
                          selectedSubSubCategory,
                          searchButtonClicked,
                          currentPage,
                          setCurrentPage,
                          isVisible,
    flashSale, trait
                      }) => {
    const [products, setProducts] = useState([]);
    const [loadingProduct, setLoadingProduct] = useState(true);
    // const [currentPage, setCurrentPage] = useState(1);
    const [totalProducts, setTotalProducts] = useState(0);
    const [spin, setSpin] = useState(false);
    const [limit, setLimit] = useState(20);

    // track page changes
    function trackPage(page) {
        window.scrollTo(0, 0);
        setCurrentPage(page);
    }

    function showTotal(total) {
        return `Total ${total} products`;
    }

    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    // fetching all products
    const fetchAllProducts = async () => {
        setSpin(true);
        const res = await axios.get(`/api/products`, {
            withCredentials: true,
            params: {
                page: currentPage,
                limit: limit,
                name: searchTerm ?? null,
                sku: skuTerm ?? null,
                category: selectedCategory !== "" ? selectedCategory : null,
                trait: trait !== "" ? trait : null,
                subcategory: selectedSubCategory !== "" ? selectedSubCategory : null,
                sub_subcategory: selectedSubSubCategory !== "" ? selectedSubSubCategory : null,
                is_visible: isVisible ? 'True' : null,
                flash_sale: flashSale ? 'True' : null
            },
        });

        setTotalProducts(res.data.count);
        setProducts(res.data.results);
        setSpin(false);
        setLoadingProduct(false);
    };

    // render on useEffect
    useEffect(() => {
        fetchAllProducts();
        // eslint-disable-next-line
    }, [currentPage, limit, searchButtonClicked]);

    return (
        <>
            {loadingProduct ? (
                <SimpleLoader height="70vh"/>
            ) : (
                <>
                    {products.length > 0 ? (
                        <Spin spinning={spin} indicator={<SimpleLoader height="100%"/>}>
                            <div className="product__table">
                                <table>
                                    <thead>
                                    <tr className="tr__head">
                                        <th className="th">Image</th>
                                        <th className="th">Name</th>
                                        <th className="th">Timestamps</th>
                                        <th className="th">Variants</th>
                                        <th className="th">RP</th>
                                        <th className="th">DP</th>
                                        <th className="th">Category</th>
                                        <th className="th">S. Attribute</th>
                                        <th className="th">Flashsale</th>
                                        {/*<th className="th">VC</th>*/}
                                        {/*<th className="th">US</th>*/}
                                        {/* <th className="th">Unit Sold</th> */}
                                        <th className="th">Visible?</th>
                                        <th className="th">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {products.map((product, index) => (
                                        <ProductItem key={index} product={product}/>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="sh__pagination__center">
                                <Pagination
                                    onShowSizeChange={onShowSizeChange}
                                    current={currentPage}
                                    onChange={trackPage}
                                    total={totalProducts}
                                    defaultPageSize={limit}
                                    showTotal={showTotal}
                                />
                            </div>
                        </Spin>
                    ) : (
                        <Spin spinning={spin} indicator={<SimpleLoader height={"30vh"}/>}>
                            <NoData/>
                        </Spin>
                    )}
                </>
            )}
        </>
    );
};

const ClonedProductTable = ({
                                searchTerm,
                                skuTerm,
                                selectedCategory,
                                selectedSubCategory,
                                searchButtonClicked,
                                currentPage,
                                setCurrentPage
                            }) => {
    const [products, setProducts] = useState([]);
    const [loadingProduct, setLoadingProduct] = useState(true);
    const [totalProducts, setTotalProducts] = useState(0);
    const [spin, setSpin] = useState(false);
    const [limit, setLimit] = useState(100);
    const [zones, setZones] = useState([]);


    // track page changes
    function trackPage(page) {
        setCurrentPage(page);
    }

    function showTotal(total) {
        return `Total ${total} products`;
    }

    // on pagination size change
    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
    };

    // fetching all products
    const fetchAllProducts = async () => {
        setSpin(true);
        const res = await axios.get(`/api/cloned-products`, {
            withCredentials: true,
            params: {
                page: currentPage,
                limit: limit,
                name: searchTerm ?? null,
                sku: skuTerm ?? null,
                category: selectedCategory !== "" ? selectedCategory : null,
                subcategory: selectedSubCategory !== "" ? selectedSubCategory : null,
            },
        });

        setTotalProducts(res.data.count);
        setProducts(res.data.results);
        setSpin(false);
        setLoadingProduct(false);
    };

    useEffect(() => {
        const getAllAreas = async () => {
            const res = await axios.get("/api/public/fundamentals/zones");
            setZones(res.data);
            console.log(res.data);
        };
        getAllAreas();
    }, []);


    // render on useEffect only when page number changes
    useEffect(() => {
        fetchAllProducts();
        // eslint-disable-next-line
    }, [currentPage, limit, searchButtonClicked]);

    return (
        <>
            {loadingProduct ? (
                <SimpleLoader height="100%"/>
            ) : (
                <>
                    {products.length > 0 ? (
                        <Spin spinning={spin} indicator={<SimpleLoader height="100%"/>}>
                            <div className="product__table">
                                <table>
                                    <thead>
                                    <tr className="tr__head">
                                        <th className="th"> Name</th>
                                        <th className="th"> Sku</th>
                                        <th className="th"> Merchant</th>
                                        <th className="th"> Zone</th>
                                        <th className="th"> Stock</th>
                                        <th className="th"> Approved?</th>
                                        <th className="th"> Visible</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {products.map((product, index) => (
                                        <ClonedProductsItem key={index} product={product} zones={zones}/>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="sh__pagination__center">
                                <Pagination
                                    onShowSizeChange={onShowSizeChange}
                                    current={currentPage}
                                    onChange={trackPage}
                                    total={totalProducts}
                                    defaultPageSize={limit}
                                    showTotal={showTotal}
                                />
                            </div>
                        </Spin>
                    ) : (
                        <Spin spinning={spin} indicator={<SimpleLoader height={"30vh"}/>}>
                            <NoData/>
                        </Spin>
                    )}
                </>
            )}
        </>
    );
};

const ClonedProductsItem = ({product, zones}) => {

    const [loadingStockUpdate, setLoadingStockUpdate] = useState(false);
    const [stock, setStock] = useState(0);
    const [isApproved, setIsApproved] = useState(() => product.is_approved);
    const [isVisible, setIsVisible] = useState(() => product.is_visible);


    //defining clonedProductApprove
    const clonedProductApprove = async (value) => {
        await axios.put(`/api/cloned-products/${product.id}`, {is_approved: value}, {withCredentials: true})
        message.success("Product Approval status Updated!")
    }

    //defining clonedProductVisible function
    const clonedProductVisible = async (value) => {
        await axios.put(`/api/cloned-products/${product.id}`, {is_visible: value}, {withCredentials: true})
        message.success("Product Visibility Updated!")
    }

    //defining productZoneChange function
    const productZoneChange = async (value) => {
        await axios.put(`/api/cloned-products/${product.id}`, {zone: value}, {withCredentials: true})
        message.success("Product Zone Updated!")
    }


    useEffect(() => {
        setStock(product.stock);
        setIsApproved(product.is_approved);
        setIsVisible(product.is_visible);
    }, [product]);

    const handleStockUpdate = async () => {
        setLoadingStockUpdate(true);
        await axios.put(`/api/cloned-products/${product.id}`, {stock}, {withCredentials: true});
        message.success("Stock updated successfully!");
        setLoadingStockUpdate(false);
    };

    return (
        <tr className="tr">
            <td className="td">{product.name}</td>
            <td className="td">
                <SkuWithCopy sku={product.sku}/>
            </td>
            <td className="td">{product.merchant?.venture_name}</td>
            <td className="td">
                <Select
                    defaultValue={product.zone}
                    style={{minWidth: '100px'}}
                    onChange={(value) => productZoneChange(value)}
                >
                    {
                        zones.map((zone, index) => {
                            return <Select.Option value={zone.id} key={index}>{zone.name}</Select.Option>
                        })
                    }
                </Select>
            </td>
            <td className="td">
                <Input.Group compact>
                    <Input
                        onPressEnter={handleStockUpdate}
                        defaultValue={stock}
                        value={stock}
                        onChange={(e) => setStock(e.target.value)}
                        style={{width: 50}}
                    />
                    <Button
                        type="primary"
                        title="Update Stock"
                        onClick={handleStockUpdate}
                        htmlType="submit"
                    >
                        {loadingStockUpdate ? <SyncOutlined spin/> : <SaveOutlined/>}
                    </Button>
                </Input.Group>
            </td>
            <td className="td">
                <Switch
                    checked={isApproved}
                    onChange={(value) => {
                        setIsApproved(value);
                        clonedProductApprove(value);
                    }}
                />
            </td>
            <td className="td">
                <Switch
                    checked={isVisible}
                    onChange={(value) => {
                        setIsVisible(value);
                        clonedProductVisible(value)
                    }}
                />
            </td>
        </tr>
    )
}
