import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import 'antd/dist/antd.css';
import App from './App';


import AuthProvider from './Contexts/AuthContext';
import GlobalStatesProvider from './Contexts/GlobalStates';

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <GlobalStatesProvider>
                <App />
            </GlobalStatesProvider>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
