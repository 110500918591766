import React, { useEffect, useState } from "react";
// import ReactExport from "react-export-excel";
import styles from './ExportProductToExcel.module.scss';
import axios from '../../config/axios';
import Loader from "../../Components/Library/Loader";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";
import moment from '../../config/moment';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const queryString = require('query-string');
const { useLocation } = require('react-router-dom');




const ExportProductsToExcel = (props) => {
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [loading, setLoading] = useState(true);
    const [dataset, setDataset] = useState([]);


    useEffect(() => {
        console.log(query);

        async function fetchProducts() {
            const res = await axios.get(`/api/products`, {
                withCredentials: true,
                params: {
                    limit: 1000,
                    category: query.category !== "" ? query.category : null,
                    subcategory: query.subcategory !== "" ? query.subcategory : null,
                    is_visible: query.is_visible !== "false" ? 'True' : null,
                },
            });
            setLoading(false);
            // console.log(res.data.results);
            let products = res.data.results;

            products.map(product => {
                var myHTML = product.description;

                var strippedHtml = myHTML.replace(/<[^>]+>/g, '');

                product.description = strippedHtml;
                product.availability = 'in stock';
                product.condition = 'new';
                product.price = parseFloat(product.regular_price).toFixed(2) + " BDT";
                product.link = 'https://nexa.store/products/' + product.slug;
                product.image_link = product.images[0];
                product.sale_price = product.regular_price !== product.discounted_price ? parseFloat(product.discounted_price).toFixed(2) + " BDT" : ""
            })

            setDataset(res.data.results);
        }

        fetchProducts();
    }, []);



    return (
        <>
            {/*{*/}
            {/*    loading ?*/}
            {/*        <SimpleLoader height={'100vh'} />*/}
            {/*        :*/}
            {/*        query.type === 'fb_catalogue' ?*/}
            {/*            <ExcelFile*/}
            {/*                element={*/}
            {/*                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>*/}
            {/*                        <button className={styles.download_button}>Download Excel for FB Catalogue</button>*/}
            {/*                    </div>*/}
            {/*                }*/}
            {/*                filename={`nexa-products-export-fb-${moment(new Date()).format('MMMM-DD-YYYY-LT')}`}*/}
            {/*            >*/}
            {/*                <ExcelSheet data={dataset} name={'Products'}>*/}
            {/*                    <ExcelColumn label="id" value="sku" />*/}
            {/*                    <ExcelColumn label="title" value="name" />*/}
            {/*                    <ExcelColumn label="description" value="description" />*/}
            {/*                    <ExcelColumn label="availability" value="availability" />*/}
            {/*                    <ExcelColumn label="condition" value="condition" />*/}
            {/*                    <ExcelColumn label="price" value="price" />*/}
            {/*                    <ExcelColumn label="link" value="link" />*/}
            {/*                    <ExcelColumn label="image_link" value="image_link" />*/}
            {/*                    <ExcelColumn label="brand" value="brand_name" />*/}
            {/*                    <ExcelColumn label="google_product_category" value="google_product_category" />*/}
            {/*                    <ExcelColumn label="fb_product_category" value="fb_product_category" />*/}
            {/*                    <ExcelColumn label="quantity_to_sell_on_facebook" value="stock" />*/}
            {/*                    <ExcelColumn label="sale_price" value="sale_price" />*/}
            {/*                    <ExcelColumn label="sale_price_effective_date" value="sale_price_effective_date" />*/}
            {/*                    <ExcelColumn label="item_group_id" value="item_group_id" />*/}
            {/*                    <ExcelColumn label="gender" value="gender" />*/}
            {/*                    <ExcelColumn label="color" value="color" />*/}
            {/*                    <ExcelColumn label="size" value="size" />*/}
            {/*                    <ExcelColumn label="age_group" value="age_group" />*/}
            {/*                    <ExcelColumn label="material" value="material" />*/}
            {/*                    <ExcelColumn label="pattern" value="pattern" />*/}
            {/*                </ExcelSheet>*/}
            {/*            </ExcelFile>*/}
            {/*            :*/}
            {/*            <ExcelFile*/}
            {/*                element={*/}
            {/*                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>*/}
            {/*                        <button className={styles.download_button}>Download Excel</button>*/}
            {/*                    </div>*/}
            {/*                }*/}
            {/*                filename={`nexa-products-export-${moment(new Date()).format('MMMM-DD-YYYY-LT')}`}*/}
            {/*            >*/}
            {/*                <ExcelSheet data={dataset} name={'Products'}>*/}
            {/*                    <ExcelColumn label="Serial" value="index" />*/}
            {/*                    <ExcelColumn label="Product SKU" value="sku" />*/}
            {/*                    <ExcelColumn label="Product Name" value="name" />*/}
            {/*                    <ExcelColumn label="Regular Price" value="regular_price" />*/}
            {/*                    <ExcelColumn label="Discounted Price" value="discounted_price" />*/}
            {/*                    <ExcelColumn label="Commission" value="cr" />*/}
            {/*                    <ExcelColumn label="Stock" value="stock" />*/}
            {/*                </ExcelSheet>*/}
            {/*            </ExcelFile>*/}
            {/*}*/}
        </>
    )
}

export default ExportProductsToExcel;