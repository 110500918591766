import { useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
//importing icons
import { AiFillSetting, AiOutlineMessage, AiOutlineUserAdd } from "react-icons/ai";
import { BiMemoryCard } from "react-icons/bi";
import { FaBloggerB, FaMobileAlt } from "react-icons/fa";
import { GiAnchor, GiConverseShoe, GiElectric, GiShinyApple } from "react-icons/gi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { RiLogoutCircleRLine, RiShoppingBasketLine } from "react-icons/ri";

//importing context
import { GlobalStates } from "../../Contexts/GlobalStates";
import { BsCartCheckFill } from "react-icons/bs";

const Sidebar = ({setUser, theme, mobile}) => {
    const location = useLocation();
    const sidebarRef = useRef(null);
    const {sidebarScrollPosition, setSidebarScrollPosition} = useContext(GlobalStates);

    useEffect(() => {
        if (sidebarRef?.current) {
            sidebarRef.current.scrollTop = sidebarScrollPosition;
        }
        // eslint-disable-next-line
    }, [sidebarRef]);

    //defining logoutHandler function
    const logoutHandler = () => {
        setUser(null);
        localStorage.clear();
        window.location.replace("/login");
    };

    //defining OnScrollHandler function
    const onScrollHandler = (e) => {
        setSidebarScrollPosition(e.target.scrollTop);
    };

    return (
        <nav ref={sidebarRef} className={mobile ? "sidebar" : "sidebar hidden_sm"} onScroll={onScrollHandler}
             id="sidebar">
            <div className="sidebar__top" style={{display: "flex", alignItems: "center"}}>
                <img src="/logo.png" width="50px" alt="" style={{marginLeft: "1.5rem", marginTop: "0rem"}}/>
                <span style={{color: "var(--color-dark)", marginLeft: "1rem", fontSize: "2rem", lineHeight: "1"}}>CBIH Backoffice</span>
            </div>
            <div className="sidebar__middle">
                <ul>
                    <Link to="/">
                        <li className={location.pathname === "/" ? "sidebar__middle--active" : null}>
                            <GiAnchor size={20}/>
                            <p>Home</p>
                        </li>
                    </Link>

                    {/*<Link to="/attendance">*/}
                    {/*    <li className={location.pathname === "/attendance" ? "sidebar__middle--active" : null}>*/}
                    {/*        <ImUserCheck size={22} />*/}
                    {/*        <p>Attendance</p>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}

                    <span style={{margin: "2rem 0", display: "block"}}>Manage CBIH</span>

                    {/*<Link to="/merchants">*/}
                    {/*    <li className={location.pathname.includes("/merchants") ? "sidebar__middle--active" : null}>*/}
                    {/*        <FaUserTie size={22} />*/}
                    {/*        <p>Merchants</p>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}

                    <Link to="/products">
                        <li className={location.pathname.includes("/products") ? "sidebar__middle--active" : null}>
                            <GiConverseShoe size={22}/>
                            <p>Products</p>
                        </li>
                    </Link>

                    <Link to="/customers">
                        <li className={location.pathname.includes("/customers") ? "sidebar__middle--active" : null}>
                            <HiOutlineUserGroup size={22}/>
                            <p>Users</p>
                        </li>
                    </Link>

                    <Link to="/create-order">
                        <li className={location.pathname === "/create-order" ? "sidebar__middle--active" : null}>
                            <AiOutlineUserAdd size={22}/>
                            <p>Create Order</p>
                        </li>
                    </Link>

                    {/*<Link to="/order-items">*/}
                    {/*    <li className={location.pathname.includes("/order-items") ? "sidebar__middle--active" : null}>*/}
                    {/*        <FaTshirt size={22} />*/}
                    {/*        <p>Order Items</p>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}

                    <Link to="/orders">
                        <li className={location.pathname.includes("/orders") ? "sidebar__middle--active" : null}>
                            <RiShoppingBasketLine size={22}/>
                            <p>Orders</p>
                        </li>
                    </Link>

                    <Link to="/b2b-orders">
                        <li className={location.pathname.includes("/b2b-orders") ? "sidebar__middle--active" : null}>
                            <BsCartCheckFill size={22}/>
                            <p>B2B Orders</p>
                        </li>
                    </Link>

                    {/*<Link to="/warehouses">*/}
                    {/*    <li className={location.pathname.includes("/warehouses") ? "sidebar__middle--active" : null}>*/}
                    {/*        <FaWarehouse size={22} />*/}
                    {/*        <p>Warehouses</p>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}

                    {/*<Link to="/areas">*/}
                    {/*    <li className={location.pathname === "/areas" ? "sidebar__middle--active" : null}>*/}
                    {/*        <BsGraphUp size={22} />*/}
                    {/*        <p>Areas</p>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}

                    {/*<Link to="/hr">*/}
                    {/*    <li className={location.pathname.includes("/hr") ? "sidebar__middle--active" : null}>*/}
                    {/*        <BsFillPeopleFill size={22} />*/}
                    {/*        <p>Human Resource</p>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}

                    {/* <Link to="/payments">
                        <Tooltip placement="left" title="Payments" arrowPointAtCenter={true}>
                            <li className={location.pathname === "/payments" ? "sidebar__middle--active" : null}>
                                <GiTakeMyMoney size={22} />
                                <p>Payment Settlements</p>
                            </li>
                        </Tooltip>
                    </Link> */}

                    <Link to="/product-specification">
                        <li className={location.pathname === "/product-specification" ? "sidebar__middle--active" : null}>
                            <BiMemoryCard size={22}/>
                            <p>Specifications</p>
                        </li>
                    </Link>

                    <Link to="/categories">
                        <li className={location.pathname === "/categories" ? "sidebar__middle--active" : null}>
                            <GiElectric size={22}/>
                            <p>Categories</p>
                        </li>
                    </Link>

                    <Link to="/sub-categories">
                        <li className={location.pathname === "/sub-categories" ? "sidebar__middle--active" : null}>
                            <FaMobileAlt size={22}/>
                            <p>Sub Categories</p>
                        </li>
                    </Link>

                    <Link to="/brands">
                        <li className={location.pathname === "/brands" ? "sidebar__middle--active" : null}>
                            <GiShinyApple size={22}/>
                            <p>Brands</p>
                        </li>
                    </Link>

                    {/* <Link to="/push-notification">
                        <li className={location.pathname === "/push-notification" ? "sidebar__middle--active" : null}>
                            <IoNotificationsOutline size={22} />
                            <p>Push Notification</p>
                        </li>
                    </Link> */}

                    <Link to="/send-sms">
                        <li className={location.pathname === "/send-sms" ? "sidebar__middle--active" : null}>
                            <AiOutlineMessage size={22}/>
                            <p>Send SMS</p>
                        </li>
                    </Link>

                    <Link to="/blogs">
                        <li className={location.pathname.includes("/blogs") ? "sidebar__middle--active" : null}>
                            <FaBloggerB size={22}/>
                            <p>Blogs</p>
                        </li>
                    </Link>

                    <Link to="/settings">
                        <li className={location.pathname === "/settings" ? "sidebar__middle--active" : null}>
                            <AiFillSetting size={22}/>
                            <p>Settings</p>
                        </li>
                    </Link>

                    {/*<Link to="/push-notification-tokens">*/}
                    {/*    <li className={location.pathname === "/push-notification-tokens" ? "sidebar__middle--active" : null}>*/}
                    {/*        <IoNotificationsOutline size={22} />*/}
                    {/*        <p>Push Notification Tokens</p>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}

                    {/* <Link to="/create-order">
                        <Tooltip placement="left" title="Create Order" arrowPointAtCenter={true}>
                            <li className={location.pathname === "/create-order" ? "sidebar__middle--active" : null}>
                                <RiShoppingBasketLine size={22} />
                                <p>Create Order</p>
                            </li>
                        </Tooltip>
                    </Link> */}

                    {/*<Link to="/calculator">*/}
                    {/*    <li className={location.pathname === "/calculator" ? "sidebar__middle--active" : null}>*/}
                    {/*        <BsCalculator size={22} />*/}
                    {/*        <p>Calculator</p>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}

                    {/*<Link to="/excel-to-json">*/}
                    {/*    <li className={location.pathname === "/excel-to-json" ? "sidebar__middle--active" : null}>*/}
                    {/*        <SiMicrosoftexcel size={22} />*/}
                    {/*        <p>Excel To Json</p>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}


                </ul>
            </div>
            <div onClick={logoutHandler} className="sidebar__bottom h-left" style={{padding: "0 4rem"}}>
                <RiLogoutCircleRLine color="var(--color-red)" size={22}/>
                <p style={{marginLeft: "2rem", color: "var(--color-red)"}}>Sign Out</p>
            </div>
        </nav>
    );
};

export default Sidebar;
