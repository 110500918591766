import { Card, Tooltip } from "antd";
import { FiEdit } from "react-icons/fi";

export default function ProductCard({ product }) {
    return (
        <Card
            style={{ height: "100%", position: "relative" }}
            hoverable
            cover={
                <img
                    alt="product"
                    src={product.images[0]}
                    style={{ height: "200px", objectFit: "contain" }}
                />
            }
            bordered={false}
        >
            <div className="sh__edit__products">
                <Tooltip title="Edit Product" placement="left">
                    <FiEdit className="sh__edit__products-icon" />
                </Tooltip>
            </div>
            <div className="sh__name__price">
                <p className="sh__name">
                    <a href={`https://cbih.com.bd/products/${product.slug}`} target="_blank" rel="noreferrer">
                        {product.name} asd
                    </a>
                </p>
                <p className="sh__price">{product.regular_price}BDT</p>
            </div>
            <div className="sh__category__selling">
                <p className="sh__category">{product.category_name}</p>
                <p className="sh__selling">{product.regular_price}BDT</p>
            </div>
            <div className="sh__sku__stock">
                <p className="sh__sku">{product.sku}</p>
                <p className="sh__stock">{product.stock} In Stock</p>
            </div>
        </Card>
    );
}
