import Layout from "../Layout";
import ShopMenu from "../Shop/ShopMenu";
import { Row, Col, Button, Pagination } from "antd";
import axiosInstance from "../../config/axios";
import { useState, useEffect } from "react";
import Loader from "../Library/Loader";
import ProductCard from "../Products/ProductCard";

export default function ViewAllCatalogue() {
    const [catalogues, setCatalogues] = useState([]);
    const [catalogueLoading, setCatalogueLoading] = useState(false);
    const [activeButton, setActiveButton] = useState(0);
    const [catalogueQuery, setCatalogueQuery] = useState("");
    const [productByCatalogueQuery, setProductByCatalogueQuery] = useState([]);
    const [productLoading, setProductLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProducts, setTotalProducts] = useState(6);

    // track page changes
    const trackPage = (page) => {
        setProductLoading(true);
        setCurrentPage(page);
    };

    // get all catalogues
    const getAllCatalogues = async () => {
        setCatalogueLoading(true);
        const res = await axiosInstance.get("/api/catalogues", {
            withCredentials: true,
        });
        // console.log(res?.data);
        setCatalogues(res.data);
        setCatalogueQuery(res.data[0].slug);
        setCatalogueLoading(false);
    };

    // get all products by catalogueQuery
    const getProductByCatalogueQuery = async () => {
        setProductLoading(true);
        const res = await axiosInstance.get(`/api/products?page=${currentPage}&limit=6&catalogue_slug=${catalogueQuery}`);
        setTotalProducts(res?.data?.count);
        setProductByCatalogueQuery(res?.data?.results);
        setProductLoading(false);
    };

    useEffect(() => {
        getAllCatalogues();
    }, []);

    useEffect(() => {
        getProductByCatalogueQuery();
        // eslint-disable-next-line
    }, [catalogueQuery, currentPage]);

    return (
        <Layout pageName="Shop">
            {/* 📕 ShopMenu component will be on top on all all pages under shop module */}
            <ShopMenu />

            <div className="sh__viewAllCataloguesButtons">
                <Row gutter={[16, 16]}>
                    {catalogueLoading ? (
                        <Loader height="10vh" />
                    ) : (
                        <>
                            {catalogues?.map((catalogue, index) => (
                                <Col span={3} key={index}>
                                    <Button
                                        onClick={() => {
                                            setActiveButton(index);
                                            setCatalogueQuery(catalogue?.slug);
                                            setCurrentPage(1);
                                        }}
                                        type={activeButton === index ? "primary" : "default"}
                                    >
                                        {catalogue.name}
                                    </Button>
                                </Col>
                            ))}
                        </>
                    )}
                </Row>
            </div>
            <div className="sh__viewAllCatalogueProducts">
                <Row gutter={[16, 16]}>
                    {productLoading ? (
                        <Loader height="60vh" />
                    ) : (
                        <>
                            {productByCatalogueQuery?.map((product, index) => (
                                <Col span={8} key={index}>
                                    <ProductCard product={product} />
                                </Col>
                            ))}
                            {productByCatalogueQuery?.length === 0 && (
                                <p style={{ textAlign: "center", width: "100%" }}>Maybe No Product Here, We'll show if on a second...</p>
                            )}
                            <div className="sh__pagination__center">
                                <Pagination current={currentPage} onChange={trackPage} total={totalProducts} defaultPageSize={6} />
                            </div>
                        </>
                    )}
                </Row>
            </div>
        </Layout>
    );
}
