import Layout from "../Layout";
import { Result, Button } from 'antd';
import { Link } from "react-router-dom";

const NotTierOne = () => {
    return (
        <Layout pageName="Unauthorized">
            <div className="card">
                <Result
                    status="403"
                    title="Ooooops!"
                    subTitle="Sorry, You Are Not Authorized To Access This Page."
                    extra={
                        <Link to="/">
                            <Button type="primary">Back Home</Button>
                        </Link>
                    }
                />
            </div>
        </Layout>
    );
}

export default NotTierOne;