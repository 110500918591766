import moment from "moment";
import { useEffect, useState } from "react";
import axios from "../../config/axios";

//importing components
import { Avatar, Button, Checkbox, Col, Divider, Form, Input, Modal, Popover, Row, Select, Steps, Tag, Timeline, message } from "antd";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";
import { colorByStatus, formattedPrice } from "../../utils/helpers";

// importing styles
import styles from "./OrderDetails.module.scss";

// importing icons
import { PrinterOutlined } from "@ant-design/icons";
import { BsTruck } from "react-icons/bs";
import ClickToCopyText from "../../Components/Library/ClickToCopyText";

const OrderDetails = ({ orderID }) => {
    const { Step } = Steps;
    const { Option } = Select;

    const [orderCurrentStep, setOrderCurrentStep] = useState(0);
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const [orderEvents, setOrderEvents] = useState([]);
    const [orderItemsStatusLoading, setOrderItemsStatusLoading] = useState(false);
    const [globalOrderStatusLoading, setGlobalOrderStatusLoading] = useState(false);
    const [selectedOrderItems, setSelectedOrderItems] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [refresh, setRefresh] = useState(null);
    const [paymentPop, setPaymentPop] = useState(false);
    const [showTrackingInfoUpdateModal, setShowTrackingInfoUpdateModal] = useState(false);

    // show order steps based on database
    const showOrderSteps = () => {
        switch (order?.status) {
            case "pending":
                setOrderCurrentStep(0);
                break;
            case "processing":
                setOrderCurrentStep(1);
                break;
            case "in route":
                setOrderCurrentStep(2);
                break;
            case "delivered":
                setOrderCurrentStep(3);
                break;
            case "cancelled":
                setOrderCurrentStep(4);
                break;
            default:
                setOrderCurrentStep(0);
                break;
        }
    };

    // handle Order Step Changes
    const OrderStepsChange = (current) => {
        switch (current) {
            case 0:
                globalOrderStatusHandler(0, "pending");
                break;
            case 1:
                globalOrderStatusHandler(2, "processing");
                break;
            case 2:
                globalOrderStatusHandler(3, "in route");
                break;
            case 3:
                globalOrderStatusHandler(4, "delivered");
                break;
            case 4:
                globalOrderStatusHandler(5, "cancelled");
                break;

            default:
                globalOrderStatusHandler(0, "pending");
                break;
        }
    };

    // global order status handler
    const globalOrderStatusHandler = async (keyId, status) => {
        axios
            .patch(`/api/orders/status/${order.id}`, { status }, { withCredentials: true })
            .then((response) => {
                message.success(response.data.msg);
                setRefresh(Math.random());
            })
            .catch((err) => {
                message.error(err.response.data.msg);
            })
            .finally(() => {
                setRefresh(Math.random());
            });
    };

    const updatePaymentStatus = (status) => {
        axios
            .patch(`/api/orders/payment/status/${order.id}`, { payment_status: status }, { withCredentials: true })
            .then((response) => {
                message.success(response.data.msg);
                setRefresh(Math.random());
                setPaymentPop(false);
            })
            .catch((err) => {
                message.error(err.response.data.msg);
            })
            .finally(() => {
                setRefresh(Math.random());
            });
    };

    // get order details from params
    const getOrderDetails = async () => {
        setLoading(true);
        const res = await axios.get(`/api/orders/${orderID}`, {
            withCredentials: true,
        });
        setOrder(res.data);
        setLoading(false);
    };

    // // get order events
    const getOrderEvents = async () => {
        const res = await axios.get(`/api/orders/deliveries/tracking/${orderID}`, { withCredentials: true });
        setOrderEvents(res.data.results);
    };

    // render all order details
    useEffect(() => {
        getOrderDetails();
        // eslint-disable-next-line
    }, [refresh]);

    useEffect(() => {
        getOrderEvents();
    }, [refresh]);

    // render and handle order global steps
    useEffect(() => {
        showOrderSteps();
        // eslint-disable-next-line
    }, [order]);

    // calculate total order price
    function calculatePrice(number1, number2) {
        return number1 * number2;
    }

    // private note handler
    const submitPrivateNote = async (notes) => {
        await axios.patch(`/api/orders/mod/${orderID}`, notes, { withCredentials: true });
        message.success("Added Private Note");
    };

    const handleUpdateOrderTrackingInfo = (values) => {
        // console.log(values)

        axios
            .patch(`/api/orders/deliveries/mod/tracking/${order.id}`, values, { withCredentials: true })
            .then((response) => {
                message.success(response.data.msg);
                setShowTrackingInfoUpdateModal(false);
                setRefresh(Math.random());
            })
            .catch((err) => {
                message.success(err.response.data.msg);
            });
    };

    return (
        <div className={styles.order_details}>
            {loading ? (
                <SimpleLoader height="80vh" />
            ) : (
                <>
                    <div className={styles.order_details_top} style={{ marginTop: 0 }}>
                        <Row gutter={[16, 16]}>
                            <Col lg={12} sm={24} xs={24}>
                                <div className={"d-flex flex-column gap-3"}>
                                    <h3 className={"d-flex align-items-center gap-2"}>
                                        {" "}
                                        Order Details
                                        <ClickToCopyText text={orderID} />
                                    </h3>
                                    <span className={"d-block text-danger"}>
                                        {" "}
                                        Placed At: {moment(order.created_at).format("MMMM D, YYYY - LT")}{" "}
                                    </span>
                                    <span className={"d-block text-primary"}>
                                        {" "}
                                        Last Update At: {moment(order.updated_at).format("MMMM D, YYYY - LT")}{" "}
                                    </span>

                                    <Popover
                                        content={
                                            <div>
                                                <Select
                                                    style={{ width: "100%" }}
                                                    defaultValue={order.payment_status}
                                                    onChange={(value) => updatePaymentStatus(value)}
                                                >
                                                    <Select.Option value={"due"}>Due</Select.Option>
                                                    <Select.Option value={"paid"}>Paid</Select.Option>
                                                </Select>
                                            </div>
                                        }
                                        title="Update Payment Status"
                                        trigger="click"
                                        open={paymentPop}
                                        onOpenChange={setPaymentPop}
                                    >
                                        <span className={"d-block text-primary"} style={{ width: "fit-content", cursor: "pointer" }}>
                                            Payment:
                                            <Tag
                                                color={colorByStatus(order.payment_status)}
                                                style={{ textTransform: "uppercase", marginLeft: ".5rem" }}
                                            >
                                                {order.payment_method} / {order.payment_status}
                                            </Tag>
                                        </span>
                                    </Popover>

                                    <span className={"d-block d-flex gap-1 text-primary"}>
                                        Tracking:
                                        {order.delivery_tracking_code ? (
                                            <div className={"d-flex gap-2"}>
                                                <span className={"text-capitalize"}>{order.delivery_service}</span>
                                                <ClickToCopyText text={order.delivery_tracking_code} />
                                            </div>
                                        ) : (
                                            " N/A"
                                        )}
                                    </span>
                                    <div className={"d-flex gap-2"}>
                                        <a href={`/invoice/${orderID}`} target="_blank" rel="noreferrer">
                                            <Button type="primary" icon={<PrinterOutlined size={20} />}>
                                                Print Invoice
                                            </Button>
                                        </a>
                                        <Button
                                            onClick={() => setShowTrackingInfoUpdateModal(true)}
                                            type="primary"
                                            danger={true}
                                            icon={<BsTruck size={20} />}
                                        >
                                            Update Tracking Info
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            {!loading && (
                                <Col lg={12} sm={24} xs={24}>
                                    <Form
                                        name="private_note_form"
                                        layout="vertical"
                                        initialValues={{
                                            private_notes: order.private_notes,
                                        }}
                                        onFinish={submitPrivateNote}
                                    >
                                        <Form.Item
                                            label="Private Notes"
                                            name="private_notes"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Add a Note to Submit",
                                                },
                                            ]}
                                            style={{ marginBottom: 10 }}
                                        >
                                            <Input.TextArea showCount maxLength={1000} autoSize />
                                        </Form.Item>

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Update
                                            </Button>
                                        </Form.Item>
                                    </Form>

                                    <div>Public Notes: {order.notes ? order.notes : "N/A"}</div>
                                </Col>
                            )}
                        </Row>
                    </div>
                    <div className={styles.order_details_bottom}>
                        <div className={styles.order_details_bottom_left}>
                            <div className={styles.customerInfo}>
                                <div className={styles.customerInfo_titleContainer}>
                                    <div className={styles.customerInfo_title}> Customer Details</div>
                                </div>
                                <div className={styles.customerInfo_detailsContainer}>
                                    <p style={{ fontWeight: "bold" }}>Name: {order?.customer?.name}</p>
                                    <p> Phone: {order?.customer?.phone_number} </p>
                                    <p>
                                        {" "}
                                        Address: {order.address}, {order.zone}, {order.city}
                                    </p>
                                </div>
                            </div>
                            <div className={styles.orderSteps}>
                                <div className={styles.orderTimeline_titleContainer}>
                                    <div className={styles.orderTimeline_title} style={{ paddingLeft: 0 }}>
                                        Order Status
                                        {globalOrderStatusLoading && "-Updating.."}
                                    </div>
                                </div>
                                <Steps
                                    current={orderCurrentStep}
                                    responsive
                                    onChange={OrderStepsChange}
                                    size="small"
                                    labelPlacement="vertical"
                                >
                                    <Step title="pending" />
                                    <Step title="processing" />
                                    <Step title="in route" />
                                    <Step title="delivered" />
                                    <Step title="cancelled" />
                                </Steps>
                            </div>
                            <div className={styles.orderItems}>
                                <div className={styles.orderItems_titleContainer}>
                                    <div className={styles.orderItems_title}>
                                        Order Items {orderItemsStatusLoading && "- Status Updating..."}
                                    </div>
                                </div>
                                <div className={styles.orderItems_detailsContainer}>
                                    <table className={styles.table}>
                                        <tbody>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>SKU</th>
                                                <th>Color</th>
                                                <th>Size</th>
                                                <th>Unit Price</th>
                                                <th>Qty</th>
                                                <th>Total</th>
                                            </tr>
                                            {order?.order_items?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Avatar src={item.image} alt={item.sku} size={55} shape="square" />
                                                    </td>
                                                    <td>{item.name}</td>
                                                    <td className={styles.hidden_mobile}>
                                                        <ClickToCopyText text={item.sku} />
                                                    </td>
                                                    <td>{item.color}</td>
                                                    <td>{item.size}</td>
                                                    <td className={styles.hidden_mobile}>৳{formattedPrice(item.order_time_price)}</td>
                                                    <th>{item.quantity}</th>
                                                    <td>৳{formattedPrice(calculatePrice(item.order_time_price, item.quantity))}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Row style={{ justifyContent: "flex-end" }}>
                                        <Col lg={14} sm={24} xs={24}>
                                            <Divider style={{ margin: "12px 0" }} />
                                            <table className={styles.table}>
                                                <tbody>
                                                    <tr>
                                                        <td> Sub Total</td>
                                                        <td style={{ textAlign: "right" }}>
                                                            {order.sub_total && <>৳{formattedPrice(order?.sub_total)}</>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> Delivery Charge</td>
                                                        <td style={{ textAlign: "right" }}>৳{order.delivery_charge}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Discount</td>
                                                        <td style={{ textAlign: "right" }}>৳{order.discount}</td>
                                                    </tr>

                                                    <tr style={{ fontSize: "1.6rem", fontWeight: "bold" }}>
                                                        <td> Total</td>
                                                        <td style={{ textAlign: "right" }}>৳{order.total}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className={styles.orderTimeline}>
                                <div className={styles.orderTimeline_titleContainer}>
                                    <div className={styles.orderTimeline_title}>Timeline & Events</div>
                                </div>
                                <div className={styles.orderTimeline_detailsContainer}>
                                    <Timeline mode="left">
                                        {orderEvents?.map((event, index) => (
                                            <Timeline.Item key={index} label={event.timestamp}>
                                                {event.msg}
                                            </Timeline.Item>
                                        ))}
                                    </Timeline>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {showTrackingInfoUpdateModal && (
                <Modal
                    open={true}
                    title={`${order.id} - Update Tracking Info`}
                    footer={null}
                    onCancel={() => {
                        setShowTrackingInfoUpdateModal(false);
                    }}
                >
                    <Form
                        layout={"vertical"}
                        className={"mt-5"}
                        onFinish={handleUpdateOrderTrackingInfo}
                        initialValues={{
                            send_sms: false,
                            delivery_service: order.delivery_service,
                            delivery_tracking_code: order.delivery_tracking_code,
                        }}
                    >
                        <Form.Item
                            name={"delivery_service"}
                            label={"Delivery Service"}
                            required={true}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select size={"large"}>
                                <Select.Option value={"pathao"}>Pathao</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name={"delivery_tracking_code"}
                            label={"Delivery Tracking Code"}
                            required={true}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={"text"} size={"large"} />
                        </Form.Item>

                        <Form.Item
                            name={"send_sms"}
                            // label={'Send SMS to Customer'}
                            valuePropName="checked"
                        >
                            <Checkbox>Send SMS to Customer</Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button size={"large"} type={"primary"} className={"w-100"} htmlType={"submit"}>
                                Update Order Tracking Info
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default OrderDetails;
