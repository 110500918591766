import Layout from '../../Components/Layout';
import CreateProductForm from "./CreateProductForm";


const CreateProduct = () => {

    return (
        <Layout pageName="Create Product">
            <CreateProductForm />
        </Layout>
    );
};

export default CreateProduct;



