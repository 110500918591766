import { useState, useEffect } from "react";
import axios from "../../config/axios";

// importing components
import { Modal, Form, Select, Input, Button, message } from "antd";

// importing icons
import { RiCloseCircleLine } from "react-icons/ri";
import SimpleLoader from "../Library/SimpleLoader";

const { Option } = Select;

const StockInEditModal = ({ modalData, setModalData }) => {

    const [allRacksForSelectedWarehouse, setAllRacksForSelectedWarehouse] = useState([]);
    const [allSubRacks, setAllSubRacks] = useState([]);
    const [xhr, setXhr] = useState(true);
    const [updateStockLoading, setUpdateStockLoading] = useState(false);

    const getAllDataForEditStockIn = async () => {
        const res = await axios.get(`/api/warehouses/racks`, {
            withCredentials: true, params: {
                warehouse: modalData.warehouse.id
            }
        })
        setAllRacksForSelectedWarehouse(res.data)

        //find the index of selected division
        let selectedRackId;
        for (let i = 0; i < res.data.length; i++) {
            if (modalData.rack?.id === res.data[i].id) {
                selectedRackId = res.data[i].id;
                break;
            }
        }

        if (!selectedRackId) {
            setXhr(false)
            return;
        }

        // get all subracks for default selected rack
        const response = await axios.get(`/api/warehouses/subracks`, {
            withCredentials: true, params: {
                rack: selectedRackId
            }
        })
        setAllSubRacks(response.data)

        setXhr(false)
    }

    useEffect(() => {
        getAllDataForEditStockIn()
    }, []);

    const getSubRacksForRack = async (id) => {
        const res = await axios.get(`/api/warehouses/subracks`, {
            withCredentials: true, params: {
                rack: id
            }
        })
        setAllSubRacks(res.data)
    }

    const handleUpdateStock = async (values) => {
        setUpdateStockLoading(true)
        await axios.patch(`/api/warehouses/stock-in/${modalData.id}`, values, { withCredentials: true })
        setUpdateStockLoading(false)
        setModalData(null)
        message.success("Updated Data!")
        window.location.reload();
    }

    return (
        <>
            {modalData && (
                <Modal
                    visible={modalData ? true : false}
                    onCancel={() => {
                        setModalData(null);
                    }}
                    afterClose={() => {
                        setModalData(null);
                    }}
                    closeIcon={<RiCloseCircleLine size={20} />}
                    width={600}
                    centered
                    footer={null}
                >
                    {xhr ? (
                        <SimpleLoader height="100%" />
                    ) : (
                        <>
                            <h2 className="primary_h2"> Update Stock In Data </h2>
                            <Form
                                name="editStock"
                                onFinish={handleUpdateStock}
                                layout="vertical"
                                initialValues={{
                                    rack: modalData.rack?.id,
                                    subrack: modalData.subrack?.id,
                                    remarks: modalData.remarks
                                }}
                            >
                                <Form.Item
                                    name="rack"
                                    label="Rack"
                                >
                                    <Select
                                        placeholder="Select a Rack"
                                        onChange={(value) => {
                                            getSubRacksForRack(value)
                                        }}
                                    >
                                        {allRacksForSelectedWarehouse?.map((option, index) => (
                                            <Option value={option.id} key={index}>
                                                {option.code}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="subrack"
                                    label="Sub Rack"
                                >
                                    <Select placeholder="Select Sub Rack">
                                        {allSubRacks?.map((option, index) => (
                                            <Option value={option.id} key={index}>
                                                {option.code}
                                            </Option>
                                        ))}
                                    </Select>

                                </Form.Item>

                                <Form.Item name="remarks" label="Remarks">
                                    <Input type="text" />
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        loading={updateStockLoading}
                                        block
                                        type="primary"
                                        htmlType="submit">
                                        Update Stock In Data
                                    </Button>
                                </Form.Item>

                            </Form>
                        </>
                    )}

                </Modal>
            )}
        </>
    );
}

export default StockInEditModal;