import { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom"
import { Pagination, Spin, Input, Button, Select, Tooltip, Modal, message, Avatar, Form, Row, Col } from "antd";

// importing components
import Layout from "../../Components/Layout";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";
import NoData from '../../Components/Library/NoData';

// importing styles
import styles from "./Customers.module.scss";

// importing axios
import axios from "../../config/axios";

//importing icons
import { CloseCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { RiCloseCircleLine } from "react-icons/ri";
import { AuthContext } from "../../Contexts/AuthContext";


const Customers = () => {

    const [form] = Form.useForm();

    const {user} = useContext(AuthContext);

    // states
    const [allCustomer, setAllCustomer] = useState([]);
    const [customerLoading, setCustomerLoading] = useState(true);
    const [customerCount, setCustomerCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [spin, setSpin] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [filters, setFilters] = useState({});
    const [refresh, setRefresh] = useState(null);

    // render customers on page change
    useEffect(() => {
        // get all customers, access admin
        (async () => {
            setSpin(true);
            const res = await axios.get(`/api/users/admin/users`, {
                withCredentials: true,
                params: {
                    page: currentPage,
                    limit: 10,
                    search_term: filters.search_term ?? null,
                    role: filters.role ?? null,
                },
            });
            setCustomerCount(res.data.count);
            setAllCustomer(res.data.results);
            setSpin(false);
            setCustomerLoading(false);
        })();
    }, [currentPage, filters, refresh]);

    const changeUserIsActive = (is_active, user_id) => {
        axios.patch(`/api/users/admin/mod/${user_id}`, {is_active}, {withCredentials: true}).then(response => {
            setRefresh(Math.random());
            message.success('User Updated!')
        });
    }

    return (
        <Layout pageName="User Management">
            <div className={styles.filter}>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={(values) => {
                        setCurrentPage(1);
                        setFilters(values);
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col lg={8} sm={24} xs={24}>
                            <Form.Item
                                name={'search_term'}
                                style={{margin: 0}}
                            >
                                <Input
                                    placeholder="Search By Customer Name/Phone"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} sm={24} xs={24}>
                            <Form.Item
                                name={'role'}
                                style={{margin: 0}}
                            >
                                <Select placeholder="Filter by Role">

                                    <Select.Option value="admin">
                                        Admin
                                    </Select.Option>


                                    <Select.Option value="customer">
                                        Customer
                                    </Select.Option>

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={8} sm={24} xs={24}>
                            <Form.Item style={{margin: 0}}>
                                <div className={styles.actions_btn_wrapper}>
                                    <Button
                                        htmlType='submit'
                                        type='primary'
                                        icon={<SearchOutlined/>}
                                    >
                                        Apply Filters
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setCurrentPage(1);
                                            form.resetFields();
                                            setFilters({});
                                        }}
                                        type='danger'
                                        icon={<CloseCircleOutlined/>}
                                        disabled={filters.search_term || filters.role ? false : true}
                                    >
                                        Clear Filters
                                    </Button>
                                </div>

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className={styles.customers}>
                {customerLoading ? (
                    <SimpleLoader height="70vh"/>
                ) : (
                    <>
                        {allCustomer.length > 0 ? (
                            <Spin spinning={spin} indicator={<SimpleLoader height="100%"/>}>
                                <div className={styles.customers_table}>
                                    <table>
                                        <thead>
                                        <tr className="tr__head">
                                            <th className="th">Name</th>
                                            <th className="th">DP</th>
                                            <th className="th">Role</th>
                                            <th className="th">Phone</th>
                                            <th className="th">Email</th>
                                            <th className="th">Registration Date</th>
                                            <th className="th">Last Login</th>
                                            <th className="th">Last Session</th>
                                            <th className="th" style={{textAlign: "center"}}>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {allCustomer.map((customer, index) => (
                                            <tr className="tr" key={index}>
                                                <td className="td"> {customer.name} </td>
                                                <td className="td">
                                                    {customer.dp ?
                                                        <Avatar
                                                            size={45}
                                                            src={customer.dp}
                                                        />
                                                        :
                                                        <Avatar size={45} icon={<UserOutlined/>}/>
                                                    }

                                                </td>
                                                <td className="td">{customer.role}</td>
                                                <td className="td">
                                                    <a href={`tel:${customer.phone_number}`}>{customer.phone_number ? customer.phone_number : 'N/A'}</a>
                                                </td>
                                                <td className="td">{customer.email ? customer.email : 'N/A'}</td>
                                                <td className="td">{moment(customer.registration_date).format("MMMM D, YYYY - LT")}</td>
                                                <td className="td">{customer.last_login ? moment(customer.last_login).format("MMMM D, YYYY - LT") : "N/A"}</td>
                                                <td className="td">{customer.last_session ? moment(customer.last_session).format("MMMM D, YYYY - LT") : "N/A"}</td>
                                                <td className="td">
                                                    <div className={styles.table_actions}>
                                                        <Tooltip title="Change User Role" placement="top">
                                                            <FiEdit
                                                                size={25}
                                                                style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    setModalData(customer);
                                                                    setShowModal(true);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                        {
                                                            user.is_tier_one &&
                                                            <>
                                                                {
                                                                    customer.is_active ?
                                                                        <Button danger={true} type={'primary'}
                                                                                onClick={() => changeUserIsActive(false, customer.id)}>Block
                                                                            User</Button>
                                                                        :
                                                                        <Button type={'primary'}
                                                                                onClick={() => changeUserIsActive(true, customer.id)}>Unblock
                                                                            User</Button>
                                                                }
                                                            </>
                                                        }
                                                    </div>

                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="sh__pagination__center">
                                    <Pagination
                                        showTotal={(total) => `Total ${total} Customers`}
                                        total={customerCount}
                                        onChange={(page) => setCurrentPage(page)}
                                        pageSize={10}
                                        current={currentPage}
                                        showSizeChanger={false}
                                        showLessItems
                                        responsive
                                    />
                                </div>
                            </Spin>
                        ) : (
                            <Spin spinning={spin} indicator={<SimpleLoader height={"30vh"}/>}>
                                <NoData message="No User Found!"/>
                            </Spin>
                        )}
                    </>

                )}

                {/* show role change modal */}
                {modalData &&
                    <RoleChangeModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        modalData={modalData}
                        setModalData={setModalData}
                    />
                }
            </div>
        </Layout>
    );
};

export default Customers;


// role change modal component
const RoleChangeModal = ({showModal, setShowModal, modalData, setModalData}) => {
    const user = modalData;
    const [role, setRole] = useState(() => user.role);
    const [roleChangeLoading, setRoleChangeLoading] = useState(false);
    const [makeTierOneLoading, setMakeTierOneLoading] = useState(false);
    const [removeTierOneLoading, setRemoveTierOneLoading] = useState(false);

    const changeUserRole = async (role) => {
        try {
            setRoleChangeLoading(true)
            await axios.patch(`/api/users/admin/change-role/askdhkajs321khk/${user.id}`,
                {role: role},
                {withCredentials: true}
            )
            message.success("User Role Updated Successfully")
            window.location.reload()
        } catch (error) {
            message.error("You are not authorized to perform this action!")
        } finally {
            setRoleChangeLoading(false)
            setShowModal(false);

        }
    }

    const makeTierOne = async () => {
        try {
            setMakeTierOneLoading(true)
            await axios.patch(`/api/users/admin/change-role/askdhkajs321khk/${user.id}`,
                {is_tier_one: true},
                {withCredentials: true}
            )
            window.location.reload()
        } catch (error) {
            message.error("You are not authorized to perform this action!")
        } finally {
            setMakeTierOneLoading(false)
            setShowModal(false);
        }
    }

    const removeTierOne = async () => {
        try {
            setRemoveTierOneLoading(true)
            await axios.patch(`/api/users/admin/change-role/askdhkajs321khk/${user.id}`,
                {is_tier_one: false},
                {withCredentials: true}
            )
            window.location.reload()
        } catch (error) {
            message.error("You are not authorized to perform this action!")
        } finally {
            setRemoveTierOneLoading(false)
            setShowModal(false);
        }
    }

    return (
        <Modal
            visible={showModal}
            onCancel={() => {
                setShowModal(false);
                setModalData(null);
            }}
            afterClose={() => {
                setModalData(null);
            }}
            closeIcon={<RiCloseCircleLine size={20}/>}
            footer={null}
            centered
        >
            <h2 className="primary_h2"> Change User Role <span style={{color: "red"}}>(!!Officials Only!!)</span></h2>
            <p style={{margin: "1rem 0"}}> User Name: {user.name} </p>
            <p style={{margin: "1rem 0"}}> Current Role: {user.role} </p>

            <div style={{margin: "2rem 0"}}>
                <span style={{marginRight: "1rem"}}> Change Role: </span>
                <Select
                    style={{width: 200}}
                    value={role}
                    onChange={(value) => {
                        setRole(value);
                        changeUserRole(value);
                    }}
                    loading={roleChangeLoading}
                    size="large"
                >
                    <Select.Option value="" hidden>
                        Filter By Role
                    </Select.Option>

                    <Select.Option value="admin">
                        Admin
                    </Select.Option>


                    <Select.Option value="customer">
                        Customer
                    </Select.Option>

                </Select>
            </div>

            <div>
                <span style={{marginRight: "1rem"}}> Make Tier One: </span>
                {user.is_tier_one ? (
                    <Button
                        type="danger"
                        onClick={removeTierOne}
                        loading={removeTierOneLoading}
                        size="large"
                    >
                        Remove From Tier One
                    </Button>
                ) : (
                    <Button
                        type="danger"
                        onClick={makeTierOne}
                        loading={makeTierOneLoading}
                        size="large"
                    >
                        Make Tier One
                    </Button>
                )}

            </div>

        </Modal>
    )
}
