export const OrderItemsStatus = [
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Accepted",
        value: "accepted",
    },
    {
        label: "Picked",
        value: "picked",
    },
    {
        label: "Delivered",
        value: "delivered",
    },
    {
        label: "Cancelled",
        value: "cancelled",
    },
    {
        label: "Returned",
        value: "returned",
    },
    {
        label: "Delivery Pending",
        value: "delivery pending",
    },
]