import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import NotTierOne from "../Components/NotTierOne/NotTierOne";
import { AuthContext } from "../Contexts/AuthContext";

const TierOneRoute = ({ component: Component, ...rest }) => {
    const { user } = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={(props) =>
                user ? (
                    <>{user.is_tier_one ? <Component {...props} /> : <NotTierOne />}</>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default TierOneRoute;
