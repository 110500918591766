import { useState, useEffect } from "react";
import axiosInstance from "../../config/axios";
import { Col, Row, Pagination } from "antd";
import Loader from "../Library/Loader";
import ProductCard from "./ProductCard";

export default function ViewAllProducts() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProducts, setTotalProducts] = useState(0);
    const limit = 5;

    // track page changes
    const trackPage = (page) => {
        setCurrentPage(page);
    };

    // fetching all products
    const fetchAllProducts = async () => {
        setLoading(true);
        const res = await axiosInstance.get(`/api/products?page=${currentPage}&limit=${limit}`, {
            withCredentials: true,
        });

        setTotalProducts(res.data.count);
        setProducts(res.data.results);
        setLoading(false);
    };

    // render on useEffect only when page number changes
    useEffect(() => {
        fetchAllProducts();
        // eslint-disable-next-line
    }, [currentPage]);

    return (
        <div className="sh__viewAllProducts">
            <Row gutter={[16, 16]}>
                {loading ? (
                    <Loader height="70vh" />
                ) : (
                    <>
                        {products?.map((product, index) => (
                            <Col span={8} key={index}>
                                <ProductCard product={product} />
                            </Col>
                        ))}
                        <div className="sh__pagination__center">
                            <Pagination current={currentPage} onChange={trackPage} total={totalProducts} pageSize={limit} />
                        </div>
                    </>
                )}
            </Row>
        </div>
    );
}
