import { Spin } from "antd";
import SimpleLoader from "../SimpleLoader/SimpleLoader";

const Loader = ({ height }) => {
    return (
        <div style={{ height: height || "10vh", width: "100%" }} className="hv-center fc">
            {/* <div className="loader">
                <h2>NEXA</h2>
                <span>www.nexa.store</span>
            </div> */}

            <img src="/logo.png" alt="loader" width="80px" />

            <div style={{ marginTop: 10 }}>
                <Spin indicator={<SimpleLoader />} />
            </div>
        </div>
    );
};

export default Loader;
