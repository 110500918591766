import Layout from "../Components/Layout";
import { Row, Col, Pagination, Tag, Button, Spin } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import axios from "../config/axios";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { formattedPrice } from "../utils/helpers";
import SimpleLoader from "../Components/SimpleLoader/SimpleLoader";

const PaymentSettlements = () => {
    const [totalTransaction, setTotalTransaction] = useState(0);
    const [allPayments, setAllPayments] = useState([]);
    const [loadingAllTransaction, setLoadingAllTransaction] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [spin, setSpin] = useState(false);
    let history = useHistory();
    const limit = 2;

    const showTotal = (total) => `Total ${total} Transactions`;

    // get all payment info in table
    const getPaymentInfo = async () => {
        setSpin(true);
        const res = await axios.get("/api/payments/transactions", {
            withCredentials: true,
            params: {
                page: currentPage,
                limit: limit,
            },
        });
        setTotalTransaction(res.data.count);
        setAllPayments(res.data.results);
        setSpin(false);
        setLoadingAllTransaction(false);
    };

    // render all payment info on useEffect
    useEffect(() => {
        getPaymentInfo();
        // eslint-disable-next-line
    }, [currentPage]);

    // handle pagination's page change
    const handleCurrentPage = (page) => {
        setCurrentPage(page);
    };

    return (
        <Layout pageName="Payments">
            <Row gutter={[16, 8]}>
                <Col span={12}>
                    <PaymentCardLayout icon={<GiMoneyStack size={70} />} title="Total Paid Amount" month="July 2021" amount="20000" />
                </Col>
                <Col span={12}>
                    <PaymentCardLayout icon={<GiTakeMyMoney size={70} />} title="Total Due Amount" month="July 2021" amount="30000" />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="sh__paymentTable">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 10 }}>
                            <h2 className="primary_h2"> Recent Transactions </h2>
                            <Button type="primary" onClick={() => history.push("/create-transaction")}>
                                Create New Transaction
                            </Button>
                        </div>

                        {loadingAllTransaction ? (
                            <SimpleLoader height="50vh" />
                        ) : (
                            <>
                                {allPayments.length > 0 ? (
                                    <Spin spinning={spin} indicator={<SimpleLoader height={"60vh"} />}>
                                        <table>
                                            <thead>
                                                <tr className="tr__head">
                                                    <th className="th">Time</th>
                                                    <th className="th">Merchant</th>
                                                    <th className="th">Account Number</th>
                                                    <th className="th">Account Type</th>
                                                    <th className="th">Total Amount</th>
                                                    <th className="th"> Reference </th>
                                                </tr>
                                            </thead>
                                            {allPayments.map((data, index) => (
                                                <tbody key={index}>
                                                    <tr className="tr">
                                                        <td className="td">{moment(data.timestamp).format("DD-MM-YYYY, LT")}</td>
                                                        <td className="td">{data.merchant.venture_name}</td>
                                                        <td className="td">{data.account.number}</td>
                                                        <td className="td">{data.account.type}</td>
                                                        <td className="td">
                                                            <Tag color="#000"> {data.amount} BDT </Tag>
                                                        </td>
                                                        <td className="td">{data.reference ?? "N/A"}</td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </table>
                                    </Spin>
                                ) : (
                                    <p>No Data!</p>
                                )}

                                <div className="pagination__wrapper">
                                    <Pagination
                                        onChange={handleCurrentPage}
                                        pageSize={limit}
                                        current={currentPage}
                                        showTotal={showTotal}
                                        size="small"
                                        total={totalTransaction}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </Layout>
    );
};

export default PaymentSettlements;

// small sub-components

// Payment Card Layout
const PaymentCardLayout = ({ icon, title, month, amount }) => {
    return (
        <div className="sh__paymentCard">
            {icon}
            <div className="sh__paymentCardLeftContent">
                <div className="sh__paymentCardLeftContentTitle">
                    <p className="sh__paymentCardLeftContentTitleHead"> {title} </p>
                </div>
                <p className="sh__paymentCardLeftContentMonth">Upto {month} </p>
                <p className="sh__paymentCardLeftContentAmount"> ৳ {formattedPrice(amount)} </p>
            </div>
        </div>
    );
};
