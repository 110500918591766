import React from 'react';
import { BsClipboardCheck } from 'react-icons/bs';
import { message, Tag } from "antd";

const ClickToCopyText = ({text}) => {
    return (
        <Tag
            style={{display: 'flex', alignItems: 'center', gap: '2px', cursor: 'pointer', width:'fit-content'}}
            onClick={() => {
                navigator.clipboard.writeText(text);
                message.success('Copied to clipboard!')
            }}
        >
            <BsClipboardCheck size={12}/>
            <span style={{marginTop: '2px'}}>{text}</span>
        </Tag>
    );
};

export default ClickToCopyText;