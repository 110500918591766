import axios from "../../config/axios";
import React, { useState, useRef } from 'react';

// importing components
import { Select, Input, InputNumber } from "antd";

// importing icons
import { AiFillDelete } from 'react-icons/ai';

const { Option } = Select;

const StockInProduct = ({ prod, products, setProducts, index, setRefresh, allRacksForSelectedWarehouse }) => {
    const [allSubRacks, setAllSubRacks] = useState([]);
    const subrackRef = useRef();

    const [rack, setRack] = useState(prod.rack);

    const getSubRacksForRack = async (id) => {
        const res = await axios.get(`/api/warehouses/subracks`, {
            withCredentials: true, params: {
                rack: id
            }
        })
        setAllSubRacks(res.data)
    }

    return (


        <tr className="tr">
            <td className="td">
                {prod.sku}
            </td>
            <td className="td">
                {prod.name}
            </td>
            <td className="td">

                <Select
                    placeholder="Select Rack"
                    style={{ width: 170 }}
                    onChange={(value, { code }) => {
                        products[index].rack = value
                        products[index].rack_code = code
                        getSubRacksForRack(value)
                        products[index].subrack = ''
                        products[index].subrack_code = ''
                        subrackRef.current.selectedIndex = -1
                        setRefresh(Math.random())
                        setRack(value)
                    }}
                    defaultValue={rack}
                >

                    {allRacksForSelectedWarehouse?.map((option, index) => (
                        <Option value={option.id} key={index} code={option.code}>
                            {option.code}
                        </Option>
                    ))}
                </Select>

            </td>

            <td className="td">
                <Select placeholder="Select Sub Rack"
                    onClick={() => { getSubRacksForRack(rack) }}
                    defaultValue={prod?.subrack_code}
                    style={{ width: 170 }}
                    onChange={(value, { code }) => {
                        products[index].subrack = value;
                        products[index].subrack_code = code;
                        setRefresh(Math.random());
                    }}
                    ref={subrackRef}
                >
                    {allSubRacks?.map((option, index) => (
                        <Option value={option.id} key={index} code={option.code}>
                            {option.code}
                        </Option>
                    ))}
                </Select>

            </td>

            <td className="td">
                <InputNumber value={prod.quantity} defaultValue={prod.quantity} onChange={(value) => {
                    console.log(value)
                    products[index].quantity = value;
                    setRefresh(Math.random());
                }} />
            </td>

            <td className="td">
                <Input
                    defaultValue={prod.remarks}
                    placeholder="Add Remarks"
                    onChange={(e) => {
                        products[index].remarks = e.target.value
                        setRefresh(Math.random())
                    }}
                />
            </td>

            <td className="td">
                <AiFillDelete
                    style={{ cursor: 'pointer' }}
                    size={20}
                    onClick={() => {
                        products.splice(index, 1);
                        setProducts([...products]);
                    }}
                />
            </td>
        </tr>

    );
}

export default StockInProduct;