import { useState, useEffect } from 'react';

// importing components
import { Button, Col, Form, Input, message, Modal, Row, Spin, Tag, Tooltip } from "antd";
import WarehouseLayout from "../../../Components/WarehouseLayout/WarehouseLayout";
import axios from "../../../config/axios";
import SimpleLoader from '../../../Components/SimpleLoader/SimpleLoader';
import NoData from '../../../Components/Library/NoData';

// importing icons
import { FiEdit } from "react-icons/fi";
import { RiCloseCircleLine } from "react-icons/ri";

const { TextArea } = Input;

const Warehouse = () => {
    const [form] = Form.useForm();
    const [addWarehouseLoading, setAddWarehouseLoading] = useState(false);
    const [allWarehouseDataLoading, setAllWarehouseDataLoading] = useState(true);
    const [spin, setSpin] = useState(false);
    const [allWarehouses, setAllWarehouses] = useState([]);
    const [showEditWarehouseModal, setShowEditWarehouseModal] = useState(false);
    const [modalDataForEdit, setModalDataForEdit] = useState(null);
    const [updateWarehouseDataLoading, setUpdateWarehouseDataLoading] = useState(false);
    const [refresh, setRefresh] = useState(null);

    useEffect(() => {
        const getAllWarehouse = async () => {
            setSpin(true)
            const res = await axios.get('/api/warehouses', { withCredentials: true })
            setAllWarehouses(res.data);
            setSpin(false)
            setAllWarehouseDataLoading(false)
        }
        getAllWarehouse()
    }, [refresh]);

    const handleAddWarehouse = async (values) => {
        setAddWarehouseLoading(true)
        await axios.post('/api/warehouses', values, { withCredentials: true })
        message.success("Added new Brand");
        setAddWarehouseLoading(false)
        form.resetFields();
        setRefresh(Math.random())
    }

    const handleUpdateWarehouse = async (values) => {
        setUpdateWarehouseDataLoading(true)
        await axios.patch(`/api/warehouses/${modalDataForEdit.id}`, values, { withCredentials: true })
        setUpdateWarehouseDataLoading(false)
        message.success("Updated Warehouse Data")
        setShowEditWarehouseModal(false)
        setRefresh(Math.random())
    }

    return (
        <WarehouseLayout>
            <Row gutter={[16, 16]}>
                <Col lg={10} sm={24} xs={24}>
                    <div className="card">
                        <h2 className="primary_h2"> Add New Warehouse </h2>

                        {/* add new warehouse form */}
                        <Form name="addNewWarehouse" form={form} onFinish={handleAddWarehouse} layout="vertical">
                            <Form.Item label="Warehouse Name" name="name" rules={[{ required: true, message: "Please Add Warehouse Name" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item label="Warehouse Code" name="code" rules={[{ required: true, message: "Please Add Warehouse Code" }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item label="Warehouse Description" name="details">
                                <TextArea rows={2} />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" loading={addWarehouseLoading} htmlType="submit" block style={{ marginTop: 10 }}>
                                    Add New Warehouse
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24}>
                    <div className="card">
                        {/* show all warehouse */}
                        <h2 className="primary_h2">All Warehouses</h2>
                        {allWarehouseDataLoading ? (
                            <SimpleLoader height="100%" />
                        ) : (
                            <>
                                {allWarehouses.length > 0 ? (
                                    <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                                        <table>
                                            <thead>
                                                <tr className="tr__head">
                                                    <th className="th"> Name </th>
                                                    <th className="th"> Code </th>
                                                    <th className="th">Details</th>
                                                    <th className="th"> Actions </th>
                                                </tr>
                                            </thead>
                                            {allWarehouses.map((warehouse, index) => (
                                                <tbody key={index}>
                                                    <tr className="tr">
                                                        <td className="td"> {warehouse.name} </td>

                                                        <td className="td">
                                                            <Tag color="#f50"> {warehouse.code} </Tag>
                                                        </td>

                                                        <td className="td" title={warehouse.details}> {warehouse.details ? warehouse.details.slice(0, 25) + "..." : 'N/A'} </td>

                                                        <td className="td" style={{ cursor: "pointer", textAlign: "center" }}>
                                                            <Tooltip title="Edit Warehouse Data" placement="left">
                                                                <FiEdit
                                                                    className="sh__edit__products-icon"
                                                                    onClick={() => {
                                                                        setModalDataForEdit(warehouse);
                                                                        setShowEditWarehouseModal(true);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </table>
                                    </Spin>
                                ) : (
                                    <Spin spinning={spin} indicator={<SimpleLoader height="100%" />}>
                                        <NoData />
                                    </Spin>
                                )}
                            </>

                        )}

                        {/* modal for edit warehouse */}
                        {modalDataForEdit && (
                            <Modal
                                visible={showEditWarehouseModal}
                                onCancel={() => {
                                    setShowEditWarehouseModal(false);
                                    setModalDataForEdit(null);
                                }}
                                afterClose={() => {
                                    setModalDataForEdit(null);
                                }}
                                closeIcon={<RiCloseCircleLine size={20} />}
                                width={600}
                                centered
                                footer={null}
                            >
                                <h2 className="primary_h2"> Update Warehouse Data </h2>
                                <Form
                                    name="updateWarehouse"
                                    onFinish={handleUpdateWarehouse}
                                    layout="vertical"
                                    initialValues={{
                                        ...modalDataForEdit,
                                    }}
                                >
                                    <Form.Item label="Warehouse Name" name="name" rules={[{ required: true, message: "Please Add Warehouse Name" }]}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item label="Warehouse Code" name="code" rules={[{ required: true, message: "Please Add Warehouse Code" }]}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item label="Warehouse Description" name="details">
                                        <TextArea rows={2} />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" block style={{ marginTop: 10 }} loading={updateWarehouseDataLoading}>
                                            Update Warehouse Data
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        )}
                    </div>
                </Col>
            </Row>
        </WarehouseLayout>
    )
}

export default Warehouse;