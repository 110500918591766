import { Alert, Badge, Card, Col, DatePicker, Divider, Row } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { GiMoneyStack } from "react-icons/gi";
import Layout from "../../Components/Layout";
import SimpleLoader from "../../Components/SimpleLoader/SimpleLoader";
import axios from "../../config/axios";
import { formattedPrice } from "../../utils/helpers";
import styles from "./Landing.module.scss";

const Landing = () => {
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState({
        number_of_orders: 0,
        number_of_pending_orders: 0,
        number_of_processing_orders: 0,
        number_of_in_route_orders: 0,
        number_of_delivered_orders: 0,
        number_of_cancelled_orders: 0,
        number_of_returned_orders: 0,
        total_sales: 0,
        total_amount_collected: 0,
        total_amount_due: 0,
        total_cod_order_amount: 0,
        total_cash_order_amount: 0,
        total_bkash_order_amount: 0,
        total_delivery_charge: 0,
        total_cod_charge: 0,
        number_of_units_sold: 0,
    });
    const [error, setError] = useState(false);

    const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

    useEffect(() => {
        (async () => {
            if (!startDate || !endDate) return;
            try {
                const {data} = await axios.get("/api/analytics/reports/date-range", {
                    withCredentials: true,
                    params: {
                        start: startDate,
                        end: endDate,
                    },
                });
                const stats = data.results;
                setStats(stats);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [endDate, startDate]);

    // color by order key
    const colorByOrderKey = (key) => {
        switch (key) {
            case "Pending Orders":
                return "#ffa502";
            case "Processing Orders":
                return "var(--color-purple)";
            case "In Route Orders":
                return "var(--color-hotpink)";
            case "Delivered Orders":
                return "var(--color-green)";
            case "Cancelled Orders":
                return "var(--color-red)";
            case "Returned Orders":
                return "var(--color-red)";
            default:
                return "#ffa502";
        }
    };

    // color by key
    const getColorByKey = (key) => {
        switch (key) {
            case "Amount Due":
                return "#eb4d4b";
            case "Amount Collected":
                return "#3867d6";
            default:
                return "#6c757d";
        }
    };

    return (
        <Layout pageName="Landing">
            {loading && <SimpleLoader height={"80vh"}/>}

            {!loading && error && (
                <div className={styles.error}>
                    <Alert message="An error occurred when fetching data." type="error" showIcon/>
                </div>
            )}

            {!loading && !error && !!stats && (
                <div>
                    <div className="d-flex justify-content-end pb-4">
                        <DatePicker.RangePicker
                            placeholder={["From Date", "To Date"]}
                            size="large"
                            onChange={(_, dateStrings) => {
                                setStartDate(dateStrings[0]);
                                setEndDate(dateStrings[1]);
                            }}
                            defaultValue={[dayjs(startDate), dayjs(endDate)]}
                            allowClear={false}
                        />
                    </div>

                    <Row gutter={[20, 20]}>
                        <Col lg={8} xs={24}>
                            <Card
                                bordered={false}
                                bodyStyle={{
                                    padding: 0,
                                }}
                                style={{
                                    height: "100%",
                                }}
                            >
                                <div className="d-flex justify-content-between py-3">
                                    <div>
                                        <h1 className={`mb-2 lh-1 ${styles.total_orders_title}`}>Total Orders</h1>
                                        <h2 className={`mb-1 ${styles.total_orders_title}`}>{stats.number_of_orders}</h2>
                                    </div>
                                    <div className={styles.icon_wrap}>
                                        <GiMoneyStack size={22}/>
                                    </div>
                                </div>
                                <Divider
                                    style={{
                                        margin: 0,
                                    }}
                                />

                                <div className="d-flex flex-column gap-2 py-3">
                                    {Object.entries({
                                        "Pending Orders": stats.number_of_pending_orders,
                                        "Processing Orders": stats.number_of_processing_orders,
                                        "In Route Orders": stats.number_of_in_route_orders,
                                        "Delivered Orders": stats.number_of_delivered_orders,
                                        "Cancelled Orders": stats.number_of_cancelled_orders,
                                        "Returned Orders": stats.number_of_returned_orders,
                                    }).map(([key, value]) => (
                                        <div className="d-flex justify-content-between" key={key}>
                                            <span className="text-secondary text-capitalize">
                                                <strong
                                                    style={{
                                                        color: colorByOrderKey(key),
                                                    }}
                                                >
                                                    {key}
                                                </strong>
                                            </span>
                                            <Badge count={value} showZero color={colorByOrderKey(key)}/>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                        <Col lg={8} xs={24}>
                            <Card
                                bordered={false}
                                bodyStyle={{
                                    padding: 0,
                                }}
                                style={{
                                    height: "100%",
                                }}
                            >
                                <div className="d-flex justify-content-between py-3">
                                    <div>
                                        <h1 className={`mb-2 lh-1 ${styles.total_sales_title}`}>Total Sales</h1>
                                        <h2 className={`mb-1 ${styles.total_sales_title}`}>{formattedPrice(stats.total_sales)}</h2>
                                    </div>
                                    <div className={styles.icon_wrap}>
                                        <GiMoneyStack size={22}/>
                                    </div>
                                </div>
                                <Divider
                                    style={{
                                        margin: 0,
                                    }}
                                />

                                <div className="d-flex flex-column gap-2 py-3">
                                    {Object.entries({
                                        "Amount Collected": stats.total_amount_collected,
                                        "COD Order Amount": stats.total_cod_order_amount,
                                        "Cash Order Amount": stats.total_cash_order_amount,
                                        "Bkash Order Amount": stats.total_bkash_order_amount,
                                        "Amount Due": stats.total_amount_due,
                                    }).map(([key, value]) => (
                                        <div className="d-flex justify-content-between" key={key}>
                                            <span
                                                className="text-capitalize"
                                                style={{
                                                    color: getColorByKey(key),
                                                }}
                                            >
                                                <strong>{key}</strong>
                                            </span>
                                            <span
                                                style={{
                                                    color: getColorByKey(key),
                                                }}
                                            >
                                                {formattedPrice(value)}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                        <Col lg={8} xs={24}>
                            <Card
                                bordered={false}
                                bodyStyle={{
                                    padding: 0,
                                }}
                                style={{
                                    height: "100%",
                                }}
                            >
                                <div className="d-flex justify-content-between py-3">
                                    <div>
                                        <h1 className={`mb-2 lh-1 ${styles.unit_sold_title}`}>Total Unit Sold</h1>
                                        <h2 className={`mb-1 ${styles.unit_sold_title}`}>{stats.number_of_units_sold}</h2>
                                    </div>
                                    <div className={styles.icon_wrap}>
                                        <GiMoneyStack size={22}/>
                                    </div>
                                </div>
                                <Divider
                                    style={{
                                        margin: 0,
                                    }}
                                />

                                {/*<div className="d-flex flex-column gap-2 py-4">*/}
                                {/*    {Object.entries({*/}
                                {/*        "Delivery Charge": stats.total_delivery_charge,*/}
                                {/*        "COD Charge": stats.total_cod_charge,*/}
                                {/*    }).map(([key, value]) => (*/}
                                {/*        <div className="d-flex justify-content-between" key={key}>*/}
                                {/*            <span className="text-secondary text-capitalize">*/}
                                {/*                <strong>{key}</strong>*/}
                                {/*            </span>*/}
                                {/*            <span className="text-secondary">{formattedPrice(value)}</span>*/}
                                {/*        </div>*/}
                                {/*    ))}*/}
                                {/*</div>*/}
                            </Card>
                        </Col>
                        <Col lg={8} xs={24}>
                            <Card
                                bordered={false}
                                bodyStyle={{
                                    padding: 0,
                                }}
                                style={{
                                    height: "100%",
                                }}
                            >
                                <div className="d-flex justify-content-between py-3">
                                    <div>
                                        <h1 className={`mb-2 lh-1 text-secondary`}>Breakdown</h1>
                                        {/*<h2 className={`mb-1 ${styles.unit_sold_title}`}>{stats.number_of_units_sold}</h2>*/}
                                    </div>
                                    <div className={styles.icon_wrap}>
                                        <GiMoneyStack size={22}/>
                                    </div>
                                </div>
                                <Divider
                                    style={{
                                        margin: 0,
                                    }}
                                />

                                <div className="d-flex flex-column gap-2 py-4">
                                    <div className="d-flex justify-content-between">
                                            <span className="text-secondary text-capitalize">
                                                <strong>Total Sales</strong>
                                            </span>
                                        <span className="text-secondary">{stats.total_sales} BDT</span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                            <span className="text-danger text-capitalize">
                                                <strong>Total Delivery Charge</strong>
                                            </span>
                                        <span className="text-danger">- {stats.total_delivery_charge}BDT </span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                            <span className="text-danger text-capitalize">
                                                <strong>Total COD Charge</strong>
                                            </span>
                                        <span className="text-danger">- {stats.total_cod_charge} BDT</span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                            <span className="text-warning text-capitalize">
                                                <strong>Total PXP</strong>
                                            </span>
                                        <span className="text-warning">- {stats.total_pxp} BDT</span>
                                    </div>
                                    <Divider style={{margin: '0 0'}} />
                                    <div className="d-flex justify-content-between">
                                            <span className="text-success text-capitalize">
                                                <strong>Gross Profit</strong>
                                            </span>
                                        <span className="text-success">
                                                {stats.total_sales - stats.total_delivery_charge - stats.total_cod_charge - stats.total_pxp} BDT
                                        </span>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </Layout>
    );
};

export default Landing;
