import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../config/axios";

//importing components
import { Button, Col, Form, Image, Input, Modal, Radio, Row, Select, Space, message } from "antd";
import { AiFillCloseCircle, AiOutlineEdit, AiOutlineSave } from "react-icons/ai";
import Layout from "../Components/Layout";
import SimpleLoader from "../Components/Library/SimpleLoader";
import ProductDescriptionEditor from "../Components/Products/ProductDescriptionEditor";
import ReorderImages from "../Components/ReorderImages/ReorderImages";

//importing icons
import { AiOutlineMinusCircle } from "react-icons/ai";

//importing api services
import Loader from "../Components/Library/Loader";
import { uploadImage, uploadVideo } from "../api/fundamentals";

//importing utils

const EditProduct = (props) => {
    const variantStockRef = useRef(null);

    const [product, setProduct] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [variantForm] = Form.useForm();
    const { Option } = Select;
    const history = useHistory();

    const [video, setVideo] = useState(null);
    const [images, setImages] = useState([]);
    const [sizeChart, setSizeChart] = useState(null);
    const [xhr, setXhr] = useState(null);
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [featuredImage, setFeaturedImage] = useState("");
    const [openModalForVariantImage, setOpenModalForVariantImage] = useState(false);
    const [openModalForVariantImageWhenCreate, setOpenModalForVariantImageWhenCreate] = useState(false);

    // handle product category and category slug
    const [productCategoryOptions, setProductCategoryOptions] = useState([]);

    // handle product sub category and sub category slug
    const [productSubCategoryOptions, setProductSubCategoryOptions] = useState([]);

    // handle product brand
    const [productBrandOptions, setProductBrandOptions] = useState([]);

    const [variants, setVariants] = useState([]);
    const [productSpecifications, setProductSpecifications] = useState([]);
    const [deletedSpecifications, setDeletedSpecifications] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [variantLoading, setVariantLoading] = useState(false);
    const [editVariant, setEditVariant] = useState(null);
    const [editVariantStock, setEditVariantStock] = useState(null);
    const [editVariantPrice, setEditVariantPrice] = useState(null);
    const [editVariantP, setEditVariantP] = useState(null);
    const [toUpdateImageVariant, setToUpdateImageVariant] = useState(null);

    useEffect(() => {
        axios.get(`/api/products/mod/${props.match.params.productID}`, { withCredentials: true }).then((response) => {
            let data = response.data;
            setProduct(response.data);
            setVariants(data.variants);
            setImages(data.images);
            setVideo(data.video);
            setSizeChart(data.size_chart);
            setFeaturedImage(data.featured_image);
            setDescription(data.description);
            setProductSpecifications(data.specifications);
            setInitialLoading(false);
            getProductSubCategories(data.category.id);
        });
    }, [props.match.params.productID, refresh]);

    const imageUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 1000, 1000).then((res) => {
                const { response, success } = res;
                if (success) {
                    setImages((images) => images.concat(response.data.secure_url));
                    setXhr(false);
                }
            });
        }
    };

    const featuredImageUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 800, 800).then((res) => {
                const { response, success } = res;
                if (success) {
                    setFeaturedImage(response.data.secure_url);
                    setXhr(false);
                }
            });
        }
    };

    const videoUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("video", e.target.files[i]);

            uploadVideo(formData).then((res) => {
                const { response, success } = res;
                if (success) {
                    setVideo(response.data.secure_url);
                    setXhr(false);
                }
            });
        }
    };

    const sizeChartUploadHandler = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            uploadImage(formData, 1000, 1000).then((res) => {
                const { response, success } = res;
                if (success) {
                    setSizeChart(response.data.secure_url);
                    setXhr(false);
                }
            });
        }
    };

    const getProductCategories = async () => {
        const res = await axios.get(
            "/api/public/categories",
            { withCredentials: true },
            {
                params: {
                    public: null,
                },
            }
        );
        setProductCategoryOptions(res.data);
    };

    //   get product sub category options
    const getProductSubCategories = async (category) => {
        const res = await axios.get(`/api/public/subcategories?category=${category}&serialize=true`, {
            withCredentials: true,
        });
        setProductSubCategoryOptions(res.data);
    };

    //   get brands
    const getProductBrands = async () => {
        const res = await axios.get("/api/public/brands?limit=500", {
            withCredentials: true,
        });
        setProductBrandOptions(res.data.results);
    };

    //   handle category value change
    const handleCategoryChange = (value) => {
        getProductSubCategories(value);
    };

    //   handle sub category value change
    const handleSubCategoryChange = (value, { index }) => {
        setProductSpecifications(productSubCategoryOptions[index].specifications);
    };

    //   get category, subcategory and brands on useEffect
    useEffect(() => {
        getProductCategories();
        getProductBrands();
    }, []);

    // update image reordering
    const updateImages = (images) => {
        setImages(images);
    };

    //defining deleteSpecification function
    const deleteSpecificationHandler = (specificationID) => {
        axios.delete(`/api/specifications/${specificationID}`, { withCredentials: true }).then((response) => {
            setDeletedSpecifications((deletedSpecifications) => deletedSpecifications.concat(specificationID));
        });
    };

    const addVariantHandler = () => {
        const variant = variantForm.getFieldsValue();
        if (!variant.size) {
            message.error("Variant Size is required!");
            return;
        }
        if (!variant.stock) {
            message.error("Variant Stock is required!");
            return;
        }

        let dup_variants = variants.filter((vari) => vari.size.toLowerCase() === variant.size.toLowerCase());
        if (dup_variants.length !== 0) {
            message.error("Duplicate Size");
            return;
        }

        variantForm.resetFields();
        let payload = {
            ...variant,
            product: product.id,
        };

        setVariantLoading(true);

        axios
            .post("/api/admin/products/variants", payload, { withCredentials: true })
            .then((response) => {
                message.success("Variant added successfully!");
                setRefresh(Math.random());
            })
            .catch((error) => {
                message.error(error.response.data.msg);
            })
            .finally(() => {
                setVariantLoading(false);
            });
    };

    const updateVariantStock = () => {
        let payload = {
            stock: editVariantStock,
        };

        axios
            .patch(`/api/admin/products/variants/${editVariant}`, payload, { withCredentials: true })
            .then((response) => {
                message.success("Variant updated successfully!");
                setRefresh(Math.random());
                setEditVariant(null);
                setEditVariantStock(null);
            })
            .catch((error) => {
                message.error(error.response.data.msg);
            });
    };

    const updateVariantPrice = () => {
        let payload = {
            price: editVariantPrice,
        };

        axios
            .patch(`/api/admin/products/variants/${editVariantP}`, payload, { withCredentials: true })
            .then((response) => {
                message.success("Variant updated successfully!");
                setRefresh(Math.random());
                setEditVariantP(null);
                setEditVariantPrice(null);
            })
            .catch((error) => {
                message.error(error.response.data.msg);
            });
    };

    const deleteProductVariant = (variantID) => {
        setVariantLoading(true);
        axios
            .delete(`/api/admin/products/variants/${variantID}`, { withCredentials: true })
            .then((response) => {
                message.success("Variant Deleted Successfully!");
                setRefresh(Math.random());
            })
            .catch((error) => {
                message.error(error.response.data.msg);
            })
            .finally(() => {
                setVariantLoading(false);
            });
    };

    const onFinish = async (values) => {
        try {
            if (images.length === 0) {
                message.error("Upload at least 1 product image!");
                return;
            }

            if (images.length > 6) {
                message.error("Too Many product image!");
                return;
            }

            if (!description) {
                message.error("Product description is required!");
                return;
            }

            if (variants.length < 1) {
                message.error("Add minimum 1 variant!");
                return;
            }

            values.featured_image = featuredImage;

            setLoading(true);

            // handle custom inputs
            values.size_chart = sizeChart;
            values.variants = variants;
            values.images = images;
            values.video = video;
            values.description = description;
            values.net_weight = values.net_weight ? parseFloat(values.net_weight).toFixed(2) : null;
            values.gross_weight = values.gross_weight ? parseFloat(values.gross_weight).toFixed(2) : null;
            if (!values.discounted_price) {
                values.discounted_price = values.regular_price;
            }

            console.log(values);

            await axios.put(`/api/products/mod/${product.id}`, values, {
                withCredentials: true,
            });
            message.success("Product Updated Successfully!");

            history.push("/products");
        } catch (error) {
            console.log(error);
            message.error(error.response.msg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout pageName="Edit Product">
            <div className="card">
                {initialLoading ? (
                    <SimpleLoader height={"100vh"} />
                ) : (
                    <Form
                        name="dynamic_form_nest_item"
                        onFinish={onFinish}
                        autoComplete="off"
                        layout="vertical"
                        scrollToFirstError={true}
                        initialValues={{
                            ...product,
                            category: product.category.id,
                            subcategory: product.subcategory.id,
                            brand: product.brand.id,
                        }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="Product Name"
                                    rules={[{ required: true, message: "Provide a valid name" }]}
                                    required
                                >
                                    <Input type="text" />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <label style={{ fontWeight: 600 }} htmlFor="">
                                    Product Images (800px x 800px recommended)
                                </label>
                                <div className="file-upload">
                                    <div className="image-upload-wrap">
                                        <input className="file-upload-input" type="file" multiple={true} onChange={imageUploadHandler} />
                                        <div className="drag-text">
                                            {xhr === "image" ? <Loader height={"159px"} /> : <h3>Drag and drop or click to add Images</h3>}
                                        </div>
                                    </div>

                                    {images.length > 0 ? (
                                        <div style={{ marginTop: "2rem" }}>
                                            <ReorderImages images={images} callback={updateImages} />
                                        </div>
                                    ) : null}
                                </div>
                            </Col>

                            <Col span={16}>
                                <label style={{ fontWeight: 600 }} htmlFor="">
                                    Product Featured Image
                                </label>
                                <div className="file-upload">
                                    <div className="image-upload-wrap">
                                        <input
                                            className="file-upload-input"
                                            type="file"
                                            multiple={false}
                                            onChange={featuredImageUploadHandler}
                                        />
                                        <div className="drag-text">
                                            {xhr === "image" ? <Loader height={"159px"} /> : <h3>Drag and drop or click to add Image</h3>}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={8}>
                                {featuredImage && (
                                    <img
                                        src={featuredImage}
                                        style={{ width: "100%", height: 300, objectFit: "contain" }}
                                        className={"mt-5"}
                                        alt="featured_image"
                                    />
                                )}
                            </Col>

                            <Col span={16}>
                                <label style={{ fontWeight: 600 }} htmlFor="">
                                    Product Video (Optional)
                                </label>
                                <div className="file-upload">
                                    <div className="image-upload-wrap">
                                        <input className="file-upload-input" type="file" multiple={false} onChange={videoUploadHandler} />
                                        <div className="drag-text">
                                            {xhr === "image" ? <Loader height={"159px"} /> : <h3>Drag and drop or click to add Video</h3>}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={8}>
                                {video && (
                                    <div className="position-relative ">
                                        <video
                                            controls={true}
                                            n
                                            src={video}
                                            autoPlay={true}
                                            muted={true}
                                            style={{ width: "100%", height: 300 }}
                                            className={"mt-5"}
                                            alt="video"
                                        />

                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 5,
                                                right: 5,
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setVideo(null);
                                            }}
                                        >
                                            <AiFillCloseCircle size={20} color={"var(--color-red)"} />
                                        </div>
                                    </div>
                                )}
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="color"
                                    label="Product Color"
                                    rules={[{ required: true, message: "Provide a valid color" }]}
                                    required
                                >
                                    <Input type="text" placeholder="black" />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="pxp" label="PXP" rules={[{ required: true, message: "Provide a valid pxp" }]} required>
                                    <Input type="number" placeholder="xxxx" />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <span style={{ color: "var(--color-red)" }}>All prices are VAT(5%) Inclusive</span>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="regular_price"
                                    label="Regular Price"
                                    rules={[{ required: true, message: "Enter product price" }]}
                                    required
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="discounted_price" label="Discounted Price (If Applicable)">
                                    <Input type="number" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="category"
                                    label="Product Category"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select Product Category",
                                        },
                                    ]}
                                >
                                    <Select placeholder="Select a Category" onChange={handleCategoryChange}>
                                        {productCategoryOptions.map((option, index) => (
                                            <Option value={option.id} key={index}>
                                                {option.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="subcategory"
                                    label="Product Sub Category"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Select Product Sub-Category",
                                        },
                                    ]}
                                >
                                    <Select placeholder="Select a Sub-Category" onChange={handleSubCategoryChange}>
                                        {productSubCategoryOptions.map((option, index) => (
                                            <Option value={option.id} key={index} index={index}>
                                                {option.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="brand"
                                    label="Product Brand"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select product brand or set as Other",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select a Brand"
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {productBrandOptions?.map((option, index) => (
                                            <Option value={option.id} key={index} name={option.name}>
                                                {option.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={16}>
                                <label style={{ fontWeight: 600 }} htmlFor="">
                                    Size Chart
                                </label>
                                <div className="file-upload">
                                    <div className="image-upload-wrap">
                                        <input
                                            className="file-upload-input"
                                            type="file"
                                            multiple={false}
                                            onChange={sizeChartUploadHandler}
                                        />
                                        <div className="drag-text">
                                            {xhr === "image" ? <Loader height={"159px"} /> : <h3>Drag and drop or click to add Image</h3>}
                                        </div>
                                    </div>

                                    {sizeChart ? (
                                        <div style={{ marginTop: "2rem" }}>
                                            <img src={sizeChart} alt="" />
                                        </div>
                                    ) : null}
                                </div>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="country_of_origin"
                                    label="Country Of Origin"
                                    rules={[{ required: true, message: "Provide Country Of Origin" }]}
                                    required
                                >
                                    <Input type="text" placeholder="Bangladesh" />
                                </Form.Item>
                            </Col>

                            {/*<Col span={8}>*/}
                            {/*    <Form.Item name="net_weight" label="Product Net Weight (in KG)">*/}
                            {/*        <Input type="number" maxLength="9" step="any" placeholder="10"/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}

                            {/*<Col span={8}>*/}
                            {/*    <Form.Item name="gross_weight" label="Product Gross Weight (in KG)">*/}
                            {/*        <Input type="number" maxLength="9" step="any" placeholder="10"/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}

                            <Col span={24}>
                                <Form.Item
                                    label="Meta Description for SEO"
                                    name="meta_description"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Add a Meta Description for SEO",
                                        },
                                    ]}
                                >
                                    <Input.TextArea showCount maxLength={155} rows={2} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {description && (
                            <Form.Item name="description" label="Product Description">
                                <ProductDescriptionEditor description={description} setDescription={setDescription} />
                            </Form.Item>
                        )}

                        <br />
                        <label htmlFor="">Product Variants</label>
                        <Form form={variantForm} name={"variant_form"}>
                            <Row gutter={[20, 20]}>
                                <Col span={4}>
                                    <Form.Item name={"size"} label={"Size"} required={true}>
                                        <Input placeholder={"XL"} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={"stock"} label={"Stock"} required={true}>
                                        <Input placeholder={"25"} type={"number"} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={"price"} label={"Price (Optional)"} required={false}>
                                        <Input placeholder={"25"} type={"number"} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label="Image (Optional)" name="image">
                                        <Button
                                            onClick={() => {
                                                setOpenModalForVariantImageWhenCreate(true);
                                            }}
                                            className="me-3"
                                        >
                                            Select Image
                                        </Button>

                                        {variantForm.getFieldsValue().image && (
                                            <img
                                                src={variantForm.getFieldsValue().image}
                                                alt="variant"
                                                style={{
                                                    width: 60,
                                                    height: 60,
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label={"Action"}>
                                        <Button
                                            loading={variantLoading}
                                            disabled={variantLoading}
                                            type={"primary"}
                                            onClick={addVariantHandler}
                                        >
                                            Add Variant
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                        <div style={{ maxWidth: 700, border: "1px solid var(--color-grey-light-3)" }} className={"mt-4"}>
                            <table>
                                <tr>
                                    <th style={{ textAlign: "left" }}>Size</th>
                                    <th style={{ textAlign: "left" }}>Stock</th>
                                    <th style={{ textAlign: "left" }}>Price</th>
                                    <th style={{ textAlign: "left" }}>Image</th>
                                    <th style={{ textAlign: "left" }}>Actions</th>
                                </tr>
                                {variants.map((variant, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{variant.size}</td>
                                            <td>
                                                {variant.id === editVariant ? (
                                                    <Input.Group compact className={"d-flex"}>
                                                        <Input
                                                            ref={variantStockRef}
                                                            style={{
                                                                width: "60px",
                                                            }}
                                                            type={"number"}
                                                            value={editVariantStock}
                                                            onChange={(e) => setEditVariantStock(e.target.value)}
                                                        />
                                                        <Button
                                                            type="primary"
                                                            className={"d-flex align-items-center"}
                                                            style={{ padding: "0 5px" }}
                                                            onClick={updateVariantStock}
                                                        >
                                                            <AiOutlineSave size={20} />
                                                        </Button>
                                                    </Input.Group>
                                                ) : (
                                                    <div
                                                        className={"d-flex align-items-center justify-content-between gap-2"}
                                                        style={{ width: "70px" }}
                                                    >
                                                        {variant.stock}
                                                        <Button
                                                            type={"link"}
                                                            onClick={() => {
                                                                setEditVariant(variant.id);
                                                                setEditVariantStock(variant.stock);
                                                            }}
                                                        >
                                                            <AiOutlineEdit size={15} />
                                                        </Button>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                {variant.id === editVariantP ? (
                                                    <Input.Group compact className={"d-flex"}>
                                                        <Input
                                                            // ref={variantStockRef}
                                                            style={{
                                                                width: "80px",
                                                            }}
                                                            type={"number"}
                                                            value={editVariantPrice}
                                                            onChange={(e) => setEditVariantPrice(e.target.value)}
                                                        />
                                                        <Button
                                                            type="primary"
                                                            className={"d-flex align-items-center"}
                                                            style={{ padding: "0 5px" }}
                                                            onClick={updateVariantPrice}
                                                        >
                                                            <AiOutlineSave size={20} />
                                                        </Button>
                                                    </Input.Group>
                                                ) : (
                                                    <div
                                                        className={"d-flex align-items-center justify-content-between gap-2"}
                                                        style={{ width: "70px" }}
                                                    >
                                                        {variant.price}
                                                        <Button
                                                            type={"link"}
                                                            onClick={() => {
                                                                setEditVariantP(variant.id);
                                                                setEditVariantPrice(variant.price);
                                                            }}
                                                        >
                                                            <AiOutlineEdit size={15} />
                                                        </Button>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <div className={"d-flex align-items-center"} style={{ width: 150 }}>
                                                    <Image width={60} src={variant.image} height={60} fallback="/no_image.png" />
                                                    <Button
                                                        type={"link"}
                                                        onClick={() => {
                                                            setOpenModalForVariantImage(true);
                                                            setToUpdateImageVariant(variant);
                                                        }}
                                                    >
                                                        <AiOutlineEdit size={15} />
                                                    </Button>
                                                </div>
                                            </td>
                                            <td>
                                                <Button
                                                    loading={variantLoading}
                                                    disabled={variantLoading}
                                                    danger={true}
                                                    onClick={() => deleteProductVariant(variant.id)}
                                                >
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>

                        <br />
                        <label htmlFor="">Product Specifications</label>

                        {/* 🔥 Specifications */}
                        {productSpecifications.length !== 0 ? (
                            <Form.List name="specifications" initialValue={productSpecifications}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey }, index) => (
                                            <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    initialValue={productSpecifications[index].name}
                                                    name={[name, "name"]}
                                                    fieldKey={[fieldKey, "name"]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Missing specification name",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Specification Name" disabled />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[name, "value"]}
                                                    fieldKey={[fieldKey, "value"]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Missing specification value",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Specification Value" />
                                                </Form.Item>
                                                {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                                                {deletedSpecifications.includes(productSpecifications[index].id) ? (
                                                    <span style={{ color: "var(--color-red)" }}>Deleted</span>
                                                ) : (
                                                    <div
                                                        onClick={() => deleteSpecificationHandler(productSpecifications[index].id)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <AiOutlineMinusCircle size={15} />
                                                    </div>
                                                )}
                                            </Space>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        ) : null}

                        <br />
                        <Form.Item>
                            <Button loading={loading} size="medium" style={{ width: "100%" }} type="primary" htmlType="submit">
                                Update Product
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>

            <SelectImageFromModal
                open={openModalForVariantImage}
                close={() => setOpenModalForVariantImage(false)}
                images={images}
                variant={toUpdateImageVariant}
                setRefresh={setRefresh}
            />

            <SelectImageFromModalWhenCreate
                open={openModalForVariantImageWhenCreate}
                close={() => setOpenModalForVariantImageWhenCreate(false)}
                images={images}
                variantForm={variantForm}
            />
        </Layout>
    );
};

export default EditProduct;

const SelectImageFromModalWhenCreate = ({ open, close, images, variantForm }) => {
    const [value, setValue] = useState(null);

    const onChange = (e) => {
        setValue(e.target.value);
        variantForm.setFieldsValue({
            image: e.target.value,
        });
        close();
    };

    return (
        <Modal title={`Select Image`} open={open} onCancel={close} footer={null} centered destroyOnClose>
            {images.length === 0 && <p>Upload at least 1 image in gallery.</p>}

            <Radio.Group onChange={onChange} value={value} style={{ marginTop: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 10,
                    }}
                >
                    {images.map((image, index) => (
                        <Radio value={image} key={index}>
                            <Image src={image} preview={false} height={100} width={100} />
                        </Radio>
                    ))}
                </div>
            </Radio.Group>
        </Modal>
    );
};

const SelectImageFromModal = ({ open, close, images, variant, setRefresh }) => {
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const handleImageSaveForVariant = () => {
        setLoading(true);
        axios
            .patch(`/api/admin/products/variants/${variant?.id}`, { image: value }, { withCredentials: true })
            .then((response) => {
                message.success("Variant Image Updated Successfully!");
                close();
                setRefresh(Math.random());
            })
            .catch((error) => {
                message.error(error.response.data.msg);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (!variant) return null;

    return (
        <Modal title={`Select Image for ${variant.size}`} open={open} onCancel={close} footer={null} centered destroyOnClose>
            <Radio.Group onChange={onChange} value={value} style={{ marginTop: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 10,
                    }}
                >
                    {images.map((image, index) => (
                        <Radio value={image} key={index}>
                            <Image src={image} preview={false} height={100} width={100} />
                        </Radio>
                    ))}
                </div>
            </Radio.Group>

            <Button type="primary" className="mt-4" block onClick={handleImageSaveForVariant} loading={loading} disabled={loading}>
                Save
            </Button>
        </Modal>
    );
};
