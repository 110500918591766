import Layout from "../Layout";
import ShopMenu from "../Shop/ShopMenu";
import { Form, Input, Button, Row, Col, message } from "antd";
import axiosInstance from "../../config/axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export default function CreateCatalogue() {
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    // getting input values
    async function onFinish(values) {
        try {
            setLoading(true);
            console.log(values);
            await axiosInstance.post("/api/catalogues", values, {
                withCredentials: true,
            });
            setLoading(false);
            message.success("New Catalogue Added.");
            history.push("/view-all-catalogue");
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <Layout pageName="Create Catalogue">
            {/* 📕 ShopMenu component will be on top on all all pages under shop module */}
            <ShopMenu />
            <div className="sh__createCatalogue">
                <Form onFinish={onFinish} autoComplete="off" layout="vertical">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Catalogue Name"
                                rules={[{ required: true, message: "Provide a valid name" }]}
                                required
                            >
                                <Input type="text" />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label="Catalogue Description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please add catalogue description",
                                    },
                                ]}
                                required
                            >
                                <Input.TextArea type="text" />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    size="medium"
                                    style={{ width: "100%" }}
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Create Catalogue
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    );
}
