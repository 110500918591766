import { useState } from "react";
import moment from "moment";
import styles from "./StockOutTable.module.scss"
import axios from "../../config/axios";

// importing components
import SkuWithCopy from "../SkuWithCopy/SkuWithCopy";
import { Tooltip, Button, message } from "antd";
import StockDetails from '../../Components/Warehouse/StockDetails/StockDetails';

// importing icons
import { FiEdit } from "react-icons/fi";

const StockOutTable = ({ index, stock, allStockOut, setModalData }) => {
    const [stockBySku, setStockBySku] = useState(null);
    const [stockBySkuLoading, setStockBySkuLoading] = useState(false);
    const [stockDetailsVis, setStockDetailsVis] = useState(false);

    // const getAllStockBySku = async (warehouse, sku) => {
    //     // try catch for ongoing error handling, will remove once fixed
    //     try {
    //         setStockBySkuLoading(true)
    //         const res = await axios.get('/api/warehouses/stock', {
    //             withCredentials: true, params: {
    //                 warehouse,
    //                 sku,
    //             }
    //         })
    //         setStockBySku(res.data.stock)
    //         setStockBySkuLoading(false)
    //     } catch {
    //         message.error('Something wrong! Contact Technical Team')
    //         setStockBySkuLoading(false)
    //     }
    // }


    return (
        <tbody key={index} className={styles.tbody}>
            <div className={styles.top_action}>
                <Button
                    type="primary"
                    title={stock}
                    className={styles.button}
                >
                    {stock}
                </Button>

                <Button
                    type="dashed"
                    title="Get Total Stock For This SKU"
                    // onClick={() => getAllStockBySku(allStockIn[stock][0].warehouse.id, stock)}
                    onClick={() => setStockDetailsVis(true)}
                >
                    {"Check Stock Details"}
                </Button>

                {/* {stockBySku &&
                    <Button
                        type="primary"
                        danger
                        title="Total Stock"
                    >
                        {stockBySku}
                    </Button>
                } */}
            </div>

            {
                allStockOut[stock].map((item, index) => {
                    return <tr className="tr" key={index}>

                        <td className="td">
                            Departed At: {moment(item.timestamp).format("MMMM D, YYYY - LT")}
                        </td>
                        <td className="td"> {item.product.name} </td>
                        <td className="td">
                            <SkuWithCopy sku={item.product.sku} />
                        </td>
                        <td className="td"> {item.warehouse.name} </td>
                        <td className="td"> {item.rack?.code ?? "N/A"} </td>
                        <td className="td"> {item.subrack?.code ?? "N/A"} </td>
                        <td className="td"> {item.quantity} </td>
                        <td className="td"> {item.remarks ?? "N/A"} </td>
                        <td className="td" style={{ cursor: "pointer" }}>
                            <Tooltip title="Edit Stock" placement="left">
                                <FiEdit
                                    className="sh__edit__products-icon"
                                    onClick={() => {
                                        setModalData(item);
                                    }}
                                />
                            </Tooltip>
                        </td>
                    </tr>
                })
            }
            {
                stockDetailsVis && <StockDetails
                    warehouse={allStockOut[stock][0].warehouse.id}
                    sku={stock}
                    setStockDetailsVis={setStockDetailsVis}
                />
            }
        </tbody>
    );
}

export default StockOutTable;